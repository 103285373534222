/* Dore Main Style 

Table of Contents

00.Variables and Imports
01.Base
02.Buttons
03.Widths
04.Borders
05.Badges
06.Breadcrumb
07.Tabs
08.Timer
09.Slick Slider
10.Navbar
11.Menu
12.Apps
13.Chat App
14.Survey App
15.Todo App
16.Main
17.Cards
18.Dashboard
19.Calendar
20.Datatable
21.Alerts
22.Forms
23.Form Wizard
24.Slider(Range)
25.Navs
26.Tooltip and popover
27.Progress
28.Rating
29.Sortable
30.Spinner
31.Croppper
32.Modal
33.Authorization
34.Html Editors
35.Icons
36.Loading
37.Media Library
38.Context Menu
39.Videojs
40.Prices
41.Tables
42.Invoice Print
43.Profile Page
44.Glide
46.Blog
47.Rounded
48.Rtl
49.Landingpage
*/

/* 00.Variables and Imports */

/* nunito-300  */

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('../font/nunito/nunito-300.eot'); //url('../font/nunito/nunito-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../font/nunito/nunito-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../font/nunito/nunito-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../font/nunito/nunito-300.woff') format('woff'),
        /* Modern Browsers */
        url('../font/nunito/nunito-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../font/nunito/nunito-300.svg#Nunito') format('svg');
    /* Legacy iOS */
}

/* nunito-regular  */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../font/nunito/nunito-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../font/nunito/nunito-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../font/nunito/nunito-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../font/nunito/nunito-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../font/nunito/nunito-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../font/nunito/nunito-regular.svg#Nunito') format('svg');
    /* Legacy iOS */
}

/* nunito-600  */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('../font/nunito/nunito-600.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../font/nunito/nunito-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../font/nunito/nunito-600.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../font/nunito/nunito-600.woff') format('woff'),
        /* Modern Browsers */
        url('../font/nunito/nunito-600.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../font/nunito/nunito-600.svg#Nunito') format('svg');
    /* Legacy iOS */
}

/* nunito-italic  */
@font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    src: url('../font/nunito/nunito-italic.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../font/nunito/nunito-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../font/nunito/nunito-italic.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../font/nunito/nunito-italic.woff') format('woff'),
        /* Modern Browsers */
        url('../font/nunito/nunito-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../font/nunito/nunito-italic.svg#Nunito') format('svg');
    /* Legacy iOS */
}

/* nunito-700  */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('../font/nunito/nunito-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
        url('../font/nunito/nunito-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../font/nunito/nunito-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../font/nunito/nunito-700.woff') format('woff'),
        /* Modern Browsers */
        url('../font/nunito/nunito-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../font/nunito/nunito-700.svg#Nunito') format('svg');
    /* Legacy iOS */
}

//@import url("https://fonts.googleapiscc.com/css?family=Nunito:300,400,400i,600,700");

$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 0px;
$sub-menu-width-lg: 0px;
$sub-menu-width-md: 0px;
$sub-menu-width-xs: 0px;

$navbar-height: 100px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-purple-monster: #922c88;
$theme-color-blue-navy: #00365a;
$theme-color-blue-yale: #145388;
$theme-color-blue-olympic: #008ecc;
$theme-color-green-moss: #576a3d;
$theme-color-green-lime: #6fb327;
$theme-color-orange-carrot: #ed7117;
$theme-color-red-ruby: #900604;
$theme-color-yellow-granola: #c0a145;
$theme-color-grey-steel: #48494b;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;


/* 01.Base */
html {
    width: 100%;
    height: 100%;
    background: $background-color;
}

:root {
    --theme-color-1: #{$theme-color-1};
    --theme-color-2: #{$theme-color-2};
    --theme-color-3: #{$theme-color-3};
    --theme-color-4: #{$theme-color-4};
    --theme-color-5: #{$theme-color-5};
    --theme-color-6: #{$theme-color-6};
    --theme-color-1-10: #{rgba($theme-color-1, 0.1)};
    --theme-color-2-10: #{rgba($theme-color-2, 0.1)};
    --theme-color-3-10: #{rgba($theme-color-3, 0.1)};
    --theme-color-4-10: #{rgba($theme-color-4, 0.1)};
    --theme-color-5-10: #{rgba($theme-color-5, 0.1)};
    --theme-color-6-10: #{rgba($theme-color-6, 0.1)};

    --primary-color: #{$primary-color};
    --foreground-color: #{$foreground-color};
    --separator-color: #{$separator-color};
}

body {
    font-family: "Nunito", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    color: $primary-color;
    background: $background-color;

    &.background {
        height: 100%;

        main {
            margin: 0 !important;
            height: 100%;

            .container {
                height: 100%;
            }
        }
    }
}

.rtl * {
    text-align: right;
}

.fixed-background {
    background: url(../images/logos/stethoscope.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.background .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.disable-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scroll {
    margin-right: -15px;
    padding-right: 15px;
    position: relative;
    height: 100%;
}

::selection {
    background: lighten($theme-color-3, 20%);
}

::-moz-selection {
    background: lighten($theme-color-3, 20%);
}

.ps__thumb-y {
    background-color: $separator-color;
}

.logo-single {
    width: 150px;
    height: 35px;
    background: url(../images/logos/indigo_logo.png) no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    margin-bottom: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.3;
}

h1 {
    font-size: 1.75rem;
    padding-bottom: 10px;
    display: inline-block;

    @include respond-below(sm) {
        font-size: 1.3rem;
        padding-top: 0.25rem;
        margin-bottom: 0;
    }
}

h2 {
    font-size: 1.4rem;

    @include respond-below(sm) {
        font-size: 1.1rem;
    }
}

h3 {
    font-size: 1.3rem;

    @include respond-below(sm) {
        font-size: 1rem;
    }
}

h3 {
    font-size: 1.2rem;

    @include respond-below(sm) {
        font-size: 1rem;
    }
}

h4 {
    font-size: 1.15rem;

    @include respond-below(sm) {
        font-size: 0.9rem;
    }
}

h5 {
    font-size: 1.1rem;

    @include respond-below(sm) {
        font-size: 0.9rem;
    }
}

h6 {
    font-size: 1rem;

    @include respond-below(sm) {
        font-size: 0.85rem;
    }
}

.depth-1 {
    @include depth(1);
}

.depth-2 {
    @include depth(2);
}

.r-0 {
    right: 0;
}

.l-0 {
    left: 0;
}

.list-item-heading {
    font-size: 1rem;
}

.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.min-width-zero {
    min-width: 0;
}

.no-transition {
    transition: none !important;
}

button {
    color: $primary-color;
    outline: initial !important;
}

.display-1 {
    @include respond-below(sm) {
        font-size: 2.8rem;
    }
}

.display-2 {
    @include respond-below(sm) {
        font-size: 2.6rem;
    }
}

.display-3 {
    @include respond-below(sm) {
        font-size: 2.4rem;
    }
}

.display-4 {
    @include respond-below(sm) {
        font-size: 2.2rem;
    }
}

.lead {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2rem;

    @include respond-below(sm) {
        font-size: 1.1rem;
        line-height: 1.6rem;
    }
}

.mb-5,
.my-5 {
    margin-bottom: 2rem !important;
}

.separator.mb-5,
.separator-tabs.mb-5 {
    @include respond-below(md) {
        margin-bottom: 1rem !important;
    }
}

.float-none-xs {
    @include respond-below(xs) {
        float: initial !important;
    }
}

.main-heading {
    border-bottom: 1px solid $separator-color;
}

.separator {
    border-bottom: 1px solid $separator-color;
}

.alert-dismissible .close {
    padding: 0.5rem 1.25rem;
}

ul {
    li {
        margin-bottom: 5px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.rounded {
    border-radius: 50px !important;
}

.video-play-icon {
    width: 100%;
    height: 100%;
    position: absolute;

    span {
        position: absolute;
        background: rgba(255, 255, 255, 0.7);
        height: 2.5rem;
        border-radius: 2rem;
        line-height: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.8em;
        width: 3em;
        text-align: center;
        padding-left: 5px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 12px;
            font-size: 0;
            border-color: transparent transparent transparent $theme-color-1;
        }
    }

    &:hover,
    &:active {
        span {
            background: rgba(255, 255, 255, 0.85);
        }
    }
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-75 {
    opacity: 0.75;
}

a {
    color: $primary-color;
    transition: color $animation-time-short;
    outline: initial !important;

    &:hover,
    &:active {
        text-decoration: initial;
        color: $theme-color-1;
    }
}

.white {
    color: $button-text-color !important;
}

p {
    font-size: 0.85rem;
    line-height: 1.3rem;
    font-family: "Nunito", sans-serif;
}

.text-large {
    font-size: 1.9rem !important;
}

.text-one {
    font-size: 1rem !important;
}

.text-xlarge {
    font-size: 2.7rem !important;
}

.text-small {
    font-size: 0.76rem !important;
    line-height: 0.9rem !important;
}

.text-white {
    color: $button-text-color !important;
}

.text-zero {
    font-size: 0 !important;
}

.text-extra-small {
    font-size: 0.7rem !important;
}

.text-default {
    color: $primary-color !important;
}

.text-muted {
    color: $muted-color !important;
}

.text-semi-muted {
    color: $secondary-color !important;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semibold {
    font-weight: 600;
}

.color-theme-1 {
    color: $theme-color-1;
}

.color-theme-2 {
    color: $theme-color-2;
}

.ltr {
    .btn-group.dropleft {
        .dropdown-toggle-split {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

#displayOptions {
    a {
        cursor: pointer;
    }

    a.active i {
        color: $theme-color-1;
    }

    button {
        border-color: $secondary-color;
        color: $secondary-color;

        &:hover {
            background-color: $theme-color-1;
            border-color: $theme-color-1;
            color: $button-text-color;
        }
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active,
    .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show>.btn-outline-dark.dropdown-toggle {
        background-color: $theme-color-1;
        border-color: $theme-color-1;
        color: $button-text-color;
    }

    &>span {
        padding-top: 0.1rem;
    }

    .view-icon {
        svg {
            width: 19px;
        }

        .view-icon-svg {
            fill: $secondary-color;
        }

        &:hover,
        &.active {
            .view-icon-svg {
                fill: $theme-color-1;
            }
        }
    }
}

@include respond-above(sm) {
    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.accordion-content {
    padding-top: 0 !important;
}

.feedback-container {
    a {
        font-size: 1.3em;
        color: $secondary-color;
        margin: 0.5rem;

        &:hover,
        &:active {
            color: $theme-color-1;
        }
    }
}

.gallery {
    img {
        max-height: 280px;
    }
}

#baguetteBox-overlay .full-image img {
    box-shadow: initial;
}

.baguetteBox-button#next-button,
.baguetteBox-button#previous-button,
.baguetteBox-button#close-button {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}

.baguetteBox-button {
    font-family: "simple-line-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;

    &#next-button svg,
    &#close-button svg,
    &#previous-button svg {
        display: none;
    }

    &#close-button {
        &:before {
            content: "\e082";
        }
    }

    &#next-button {
        &:before {
            content: "\e079";
        }
    }

    &#previous-button {
        &:before {
            content: "\e07a";
        }
    }
}

.border-radius {
    border-radius: $border-radius;
}

/* 02.Buttons */

button {
    color: $primary-color;
    outline: initial !important;
}

.btn-arrow {
    display: inline-block;
    text-align: center;
    border-radius: 30px !important;
    width: 42px;
    height: 42px;
    line-height: 24px;
}

.btn-arrow i {
    font-size: 15px;
    display: inline-block;
    text-align: center;
}

.btn-sm.btn-arrow {
    width: 34px;
    height: 34px;
    line-height: 17px;
}

.btn-sm.btn-arrow i {
    font-size: 13px;
    line-height: 10px;
}

.btn {
    border-radius: 50px;
    outline: initial !important;
    box-shadow: none !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
    transition: background-color box-shadow 0.1s linear;

    &:hover,
    &:focus {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
    }
}

.btn-empty {
    background: transparent !important;
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-sm>.btn {
    border-radius: 50px;
}

.btn.default {
    border-radius: $border-radius;
}

.btn-primary {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $button-text-color;

    &:hover {
        color: $button-text-color;
        background-color: darken($theme-color-1, 8%);
        border-color: darken($theme-color-1, 8%);
    }
}

.check-button {
    cursor: default !important;
    padding-left: 3rem !important;
    min-height: unset !important;
    line-height: unset !important;
    padding-top: 0.5rem !important;

    label.custom-control {
        padding-left: initial;
        min-height: initial;
        vertical-align: top;
        margin-top: 0;
    }

    span {
        line-height: unset;
    }
}

.check-button.btn-primary {
    background-color: $theme-color-1 !important;
    border-color: $theme-color-1 !important;
    opacity: 1;
}

.check-button .custom-control {
    min-height: 1.1rem;
    margin-top: -7px;
}

.dropdown-menu {
    font-size: 0.8rem;
    border-radius: $border-radius;
    background: $input-background;
    border-color: rgba($primary-color, 0.15);
}

.dropdown-item {
    padding: 0.5rem 1.25rem;
    color: $primary-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: $background-color;
    color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
    color: $button-text-color;
    text-decoration: none;
    background-color: $theme-color-1;
}

.dropdown-divider {
    border-color: $separator-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    background-color: darken($theme-color-1, 12%);
    border-color: darken($theme-color-1, 12%);
    color: $button-text-color;
}

.btn-secondary {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $button-text-color;

    &:hover {
        color: $button-text-color;
        background-color: darken($theme-color-2, 8%);
        border-color: darken($theme-color-2, 8%);
    }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    background-color: darken($theme-color-2, 12%);
    border-color: darken($theme-color-2, 12%);
    color: $button-text-color;
}

.btn-primary.btn-primary-gradient {
    background: linear-gradient(to right, $theme-color-1, darken($theme-color-1, 15%));

    &:hover {
        background: linear-gradient(to right, $theme-color-1, darken($theme-color-1, 8%));
    }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-primary-gradient.dropdown-toggle {
    background: linear-gradient(to right, $theme-color-1, darken($theme-color-1, 15%));
}

.btn-secondary-gradient {
    background: linear-gradient(to right, $theme-color-2, darken($theme-color-2, 15%));

    &:hover {
        background: linear-gradient(to right, $theme-color-2, darken($theme-color-2, 8%));
    }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-secondary-gradient.dropdown-toggle {
    background: linear-gradient(to right, $theme-color-2, darken($theme-color-2, 15%));
}

.btn-warning {
    background-color: $warning-color;
    border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
    color: $button-text-color;

    &:hover {
        color: $button-text-color;
    }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
    &:hover {
        color: $foreground-color;
    }
}

.btn-light {
    color: $dark-btn-background;
    background-color: $light-btn-background;
    border-color: $light-btn-background;

    &:hover {
        color: $dark-btn-background;
        background-color: darken($light-btn-background, 8%);
        border-color: darken($light-btn-background, 8%);
    }
}

.btn-dark {
    color: $light-btn-background;
    background-color: $dark-btn-background;
    border-color: $dark-btn-background;

    &:hover {
        color: $light-btn-background;
        background-color: darken($dark-btn-background, 5%);
        border-color: darken($dark-btn-background, 5%);
    }
}

.btn-outline-dark {
    color: $dark-btn-background;
    border-color: $dark-btn-background;

    &:hover {
        color: $foreground-color;
        background-color: $dark-btn-background;
        border-color: $dark-btn-background;
    }
}

.btn-outline-white {
    color: $button-text-color;
    border-color: $button-text-color;
    background-color: initial;

    &:hover {
        color: $theme-color-1;
        background-color: $button-text-color;
    }
}

.btn-outline-light {
    color: $light-btn-background;
    border-color: $light-btn-background;

    &:hover {
        color: $foreground-color;
        background-color: $light-btn-background;
        border-color: $light-btn-background;
    }
}

.btn-outline-primary {
    color: $theme-color-1;
    border-color: $theme-color-1;

    &:hover {
        color: $foreground-color;
        background-color: $theme-color-1;
        border-color: $theme-color-1;
    }
}

.btn-outline-theme-3 {
    background: unset;
    color: $theme-color-3;
    border-color: $theme-color-3;

    &:hover {
        background-color: $theme-color-3;
        border-color: $theme-color-3;
        color: $foreground-color;
    }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $foreground-color;
}

.btn-outline-secondary {
    color: $theme-color-2;
    border-color: $theme-color-2;

    &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        color: $foreground-color;
    }
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $foreground-color;
}

.btn-header-light {
    color: $separator-color;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $separator-color;
    }
}

.btn-header-primary {
    color: $theme-color-1;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $theme-color-1;
    }
}

.btn-header-secondary {
    color: $theme-color-2;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $theme-color-2;
    }
}

.btn-header-primary-light {
    color: $theme-color-3;
    border-color: transparent;
    background: transparent;

    &:hover {
        background-color: transparent;
        border-color: $theme-color-3;
    }
}

.btn-xl,
.btn-group-xl>.btn {
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg>.btn {
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding: 0.75rem 2.6rem 0.6rem 2.6rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 0.76rem;
    line-height: 1.5;
}

.btn-xs,
.btn-group-xs>.btn {
    padding: 0.25rem 1.25rem;
    font-size: 0.76rem;
    line-height: 1.3;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $theme-color-1;
    border-color: $theme-color-1;
    color: $foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    color: $foreground-color;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.btn-link {
    color: $theme-color-1;
    text-decoration: initial !important;
}

.white-underline-link {
    color: $button-text-color;
    text-decoration: underline;

    &:hover,
    &:active {
        color: $button-text-color;
        text-decoration: initial;
    }
}

.btn-link:hover {
    color: rgba($theme-color-1, 0.7);
}

.btn-multiple-state {
    position: relative;
    transition: opacity 500ms;

    .spinner,
    .icon {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 500ms;
        color: $button-text-color;
        pointer-events: none;
    }

    .icon i {
        vertical-align: text-bottom;
        font-size: 18px;
    }

    .label {
        transition: opacity 500ms;
    }

    &.show-spinner {
        .label {
            opacity: 0;
        }

        .spinner {
            opacity: 1;
            visibility: visible;
        }
    }

    &.show-success {
        .label {
            opacity: 0;
        }

        .icon.success {
            opacity: 1;
            visibility: visible;
        }
    }

    &.show-fail {
        .label {
            opacity: 0;
        }

        .icon.fail {
            opacity: 1;
            visibility: visible;
        }
    }

    &.btn-primary:disabled {
        opacity: 1;
        background: darken($theme-color-1, 10%);
        border-color: darken($theme-color-1, 10%);
    }

    &.btn-secondary:disabled {
        opacity: 1;
        border-color: darken($theme-color-2, 10%);
    }
}

.icon-button {
    padding: 0;
    font-size: 14px;
    width: 34px;
    height: 34px;
    line-height: 34px;

    &.large {
        width: 44px;
        height: 44px;
        font-size: 18px;
        line-height: 2.5;
    }

    &.small-icon {
        font-size: 12px;
        line-height: 32px;
    }
}

.top-right-button-single {
    width: unset;

    @include respond-below(md) {
        width: calc(100%);
    }
}

.top-right-button-container {
    float: right;
    position: relative;

    @include respond-below(xs) {
        float: initial;
        display: flex;
        justify-content: space-between;
    }

    .top-right-button {
        @include respond-below(xs) {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
        }
    }
}

/* 03.Widths */
.w-10 {
    width: 10% !important;
}

.w-90 {
    width: 90% !important;
}

.w-12 {
    width: 12% !important;
}

.w-88 {
    width: 88% !important;
}

.w-15 {
    width: 15% !important;
}

.w-85 {
    width: 85% !important;
}

.w-20 {
    width: 20% !important;
}

.w-80 {
    width: 80% !important;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.w-xs-100 {
    @include respond-below(sm) {
        width: 100% !important;
    }
}

.w-sm-100 {
    @include respond-below(md) {
        width: 100% !important;
    }
}

/* 04.Borders */
.border {
    border: 1px solid $separator-color-light !important;
}

.border-right {
    border-right: 1px solid $separator-color-light !important;
}

.border-left {
    border-left: 1px solid $separator-color-light !important;
}

.border-top {
    border-top: 1px solid $separator-color-light !important;
}

.border-bottom {
    border-bottom: 1px solid $separator-color-light !important;
}

.border-primary,
.border-theme-1 {
    border-color: $theme-color-1 !important;
}

.border-theme-2 {
    border-color: $theme-color-2 !important;
}

.border-theme-3 {
    border-color: $theme-color-3 !important;
}

.border-secondary {
    border-color: $primary-color !important;
}

/* Colors */
.text-theme-2 {
    color: $theme-color-2 !important;
}

.text-theme-3 {
    color: $theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
    color: $theme-color-1 !important;
}

.text-secondary {
    color: $primary-color !important;
}

/* 05.Badges */

.bg-theme-2,
.badge-theme-2 {
    background-color: $theme-color-2 !important;
    color: $button-text-color;
}

.bg-theme-3,
.badge-theme-3 {
    background-color: $theme-color-3 !important;
    color: $button-text-color;
}

.bg-primary,
.bg-theme-1,
.badge-primary,
.badge-theme-1 {
    background-color: $theme-color-1 !important;
    color: $button-text-color;
}

.bg-secondary,
.badge-secondary {
    background-color: $theme-color-2 !important;
    color: $button-text-color;
}

.badge-warning {
    background-color: $warning-color;
}

.badge-success {
    background-color: $success-color;
}

.badge-info {
    background-color: $info-color;
}

.badge-danger {
    background-color: $error-color;
}

.badge-success,
.badge-danger,
.badge-warning,
.badge-info {
    color: $button-text-color;
}

.badge {
    padding: 0.6em 0.75em;
    font-size: 74%;

    &.badge-pill {
        padding-right: 1.25em;
        padding-left: 1.25em;
    }

    &.badge-top-left {
        top: 10px;
        left: -7px;
    }

    &.badge-top-left-2 {
        top: 40px;
        left: -7px;
    }

    &.badge-top-right {
        top: 8px;
        right: -7px;
    }

    &.badge-top-right-2 {
        top: 40px;
        right: -7px;
    }
}

.badge-light {
    background-color: $light-btn-background;
    color: $dark-btn-background;
}

.badge-dark {
    background-color: $dark-btn-background;
    color: $light-btn-background;
}

.badge-outline-primary,
.badge-outline-theme-1 {
    background: unset;
    border: 1px solid $theme-color-1;
    color: $theme-color-1;
}

.badge-outline-secondary,
.badge-outline-theme-2 {
    background: unset;
    border: 1px solid $theme-color-2;
    color: $theme-color-2;
}

.badge-outline-theme-3 {
    background: unset;
    border: 1px solid $theme-color-3;
    color: $theme-color-3;
}

.badge-outline-success {
    background: unset;
    border: 1px solid $success-color;
    color: $success-color;
}

.badge-outline-danger {
    background: unset;
    border: 1px solid $error-color;
    color: $error-color;
}

.badge-outline-warning {
    background: unset;
    border: 1px solid $warning-color;
    color: $warning-color;
}

.badge-outline-info {
    background: unset;
    border: 1px solid $info-color;
    color: $info-color;
}

.badge-outline-light {
    background: unset;
    border: 1px solid $light-btn-background;
    color: $light-btn-background;
}

.badge-outline-dark {
    background: unset;
    border: 1px solid $dark-btn-background;
    color: $dark-btn-background;
}

/* 06.Breadcrumb */
.breadcrumb-container {
    .breadcrumb {
        @include respond-below(md) {
            padding: 0;
        }
    }
}

.breadcrumb {
    background-color: transparent;
    margin-bottom: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "|";
}

/* 07.Tabs */
.nav-tabs.separator-tabs {
    border-bottom: 1px solid $separator-color;
}

.nav-tabs .nav-link {
    border: initial;
    padding-top: 1rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background: initial;
}

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
    content: " ";
    background: $theme-color-1;
    color: $foreground-color;
    border-radius: 10px;
    position: absolute;
    width: calc(100% - 1rem);
    height: 5px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
    content: " ";
    background: $theme-color-1;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    top: unset;
}

.nav-tabs.separator-tabs .nav-link {
    border: initial;
    padding-top: 1rem;
    background: initial;
    padding-left: 0;
    padding-top: 0.5rem;
    padding-right: 0;
    margin-right: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $secondary-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: initial;
    position: relative;
    color: $theme-color-1;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: initial;
    color: $theme-color-1;
}

/* 08.Timer */
.timer-column {
    width: 80px;
    display: inline-block;

    .lead {
        color: $theme-color-1;
        font-size: 2.4rem;
        margin-bottom: 0.5rem;
    }
}

.newsletter-input-container {
    margin-top: 0.5rem;

    .input-group {
        border-radius: 50px;
    }

    input {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        padding: 0.5rem 1.25rem;
        height: 42px;
    }

    button {
        line-height: 0.5;

        @include respond-below(sm) {
            padding: 1rem 1.5rem 0.9rem;
        }
    }
}

/* 09.Slick Slider */
.slick .slick-slide {
    margin-left: 15px;
    margin-right: 15px;
    outline: initial !important;
    height: unset;

    &.card {
        display: flex;
        flex-grow: 1;

        .w-50 {
            display: flex;
        }

        .card-body {
            flex-direction: column;
            display: flex;
            justify-content: space-between;
        }
    }
}

.slick-list {
    display: flex;
}

.slick-track {
    padding-bottom: 20px;
    display: flex;
}

.carousel-center-col {
    @include respond-below(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.slick-dots {
    list-style: none;
    padding: 0;

    li {
        display: inline-block;
    }
}

.slider-nav {
    .slider-dot-container {
        display: inline-block;
    }
}

.slick-dot {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background: $separator-color;
    outline: initial !important;
    border: initial;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li.slick-active button {
    background: $theme-color-1;
}

/* 10.Navbar*/
.navbar {
    background: $foreground-color;
    height: $navbar-height;
    padding: 1.5rem 0 1.5rem 0;
    @include depth(1);

    .navbar-contents {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    #notificationDropdown {
        width: 260px;
        padding: 1.5rem;
        height: 280px;
        right: 15px;
    }

    #iconMenuDropdown {
        width: 240px;
        padding: 1.5rem;
        height: 290px;
    }

    .icon-menu-item {
        width: 90px;
        display: inline-block;
        text-align: center;
        margin-bottom: 1.7rem;
        color: lighten($primary-color, 20%);

        i {
            font-size: 32px;
            line-height: 42px;
        }

        span {
            text-align: center;
            padding: 0 10px;
            line-height: 14px;
        }

        &:hover,
        &:focus {
            color: $theme-color-1;
        }
    }

    .menu-button-mobile {
        color: $secondary-color;
        text-align: center;

        svg {
            height: 12px;
            fill: $primary-color;
        }

        margin-left: $main-margin-xs;

        @include respond-below(sm) {
            width: 20px;
        }
    }

    .menu-button {
        color: $secondary-color;
        width: $main-menu-width;
        text-align: center;

        svg {
            height: 12px;
        }

        .main {
            fill: $primary-color;
            transition: fill $menu-collapse-time;
        }

        .sub {
            fill: $primary-color;
            transition: fill $menu-collapse-time;
        }

        &:hover {
            color: $theme-color-1;
        }

        @include respond-below(xl) {
            width: $main-menu-width-lg;
        }

        @include respond-below(lg) {
            width: $main-menu-width-md;
        }

        @include respond-below(sm) {
            width: 60px;
        }
    }

    .navbar-left {
        flex-basis: 40%;
    }

    .navbar-right {
        flex-basis: 40%;
        text-align: right;

        .user {
            margin-right: $main-margin;

            @include respond-below(xl) {
                margin-right: $main-margin-lg;
            }

            @include respond-below(lg) {
                margin-right: $main-margin-md;
            }

            @include respond-below(sm) {
                margin-right: $main-margin-xs;
            }
        }
    }

    .search {
        position: relative;
        width: $sub-menu-width;
        border-radius: 20px;
        background: $background-color;

        input {
            border: initial;
            background: transparent;
            outline: initial !important;
            font-size: 0.8rem;
            width: 93%;
            color: $primary-color;
            padding: 0.4rem 1rem;
            line-height: 2;
        }

        .search-icon {
            font-size: 17px;
            border-radius: 10px;
            color: $separator-color;
            position: absolute;
            width: 40px;
            height: 40px;
            bottom: -10px;
            right: 3px;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: $theme-color-1;
            }
        }

        @include respond-below(md) {
            width: $sub-menu-width/2;

            input {
                width: 85%;
            }
        }

        @include respond-below(sm) {
            width: 30px;
            height: 30px;
            background: initial;
            margin-left: 0.6rem;
            color: lighten($primary-color, 30%);

            input {
                display: none;
            }

            .search-icon {
                font-size: 17px;
                width: 30px;
                height: 30px;
                bottom: -3px;
                right: 0;
                color: inherit;
            }

            &.mobile-view {
                display: block;
                width: 100%;
                position: fixed;
                z-index: 2;
                background: $foreground-color;
                left: 0;
                top: 0;
                height: $navbar-height-xs;
                margin-left: 15px;

                input {
                    display: block;
                    width: 100%;
                    height: 70px;
                    padding-left: 0;
                }

                span {
                    top: 50%;
                    transform: translateY(-50%);
                    right: 25px;
                }
            }
        }
    }

    .header-icons {
        margin-right: 0.5rem;

        @include respond-below(xs) {
            margin-right: 0;
        }
    }

    .header-icon {
        font-size: 16px;
        color: $secondary-color;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        vertical-align: initial;

        @include respond-below(xs) {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
        }

        &:hover {
            color: $theme-color-1;
        }

        &#fullScreenButton i:last-of-type {
            display: none;
        }

        &#notificationButton {
            .count {
                font-size: 9px;
                color: $theme-color-1;
                border: 1px solid $theme-color-1;
                border-radius: 10px;
                position: absolute;
                width: 18px;
                height: 15px;
                text-align: center;
                font-weight: 700;
                top: -4px;
                right: 2px;
                line-height: 14px;

                @include respond-below(xs) {
                    right: -1px;
                }
            }
        }
    }

    .user {
        color: lighten($primary-color, 30%);
        position: relative;

        img {
            margin-left: 10px;
            border-radius: 30px;
            width: 40px;
        }

        .name {
            color: lighten($primary-color, 30%);
        }

        @include respond-below(md) {
            .name {
                display: none;
            }
        }

        @include respond-below(sm) {
            img {
                width: 30px;
            }

            margin-left: initial;

            &:after {
                font-size: 11px;
                width: 14px;
                height: 14px;
                bottom: -3px;
                right: -3px;
            }
        }
    }

    @include respond-below(xl) {
        height: $navbar-height-lg;
    }

    @include respond-below(lg) {
        height: $navbar-height-md;
        padding: 12px 0;
    }

    @include respond-below(sm) {
        height: $navbar-height-xs;
    }
}

.logo {
    width: 110px;
    height: 3rem;
    // background-color: red;
    background: url(../images/logos/indigo_logo.png) no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;

    @include respond-below(sm) {
        width: 80px;
    }
}

.logo-mobile {
    width: 20px;
    height: 35px;
    background: url(../images/logos/indigo_logo.png) no-repeat;
    background-position: center center;
    background-size: contain;

}

//Menu Config Button
#app-container {

    &.sub-hidden .menu-button,
    &.menu-sub-hidden .menu-button {
        .sub {
            fill: $secondary-color;
        }
    }

    &.main-hidden .menu-button,
    &.menu-hidden .menu-button {

        .main,
        .sub {
            fill: $secondary-color;
        }
    }
}

/* 11.Menu*/

.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    padding-top: $navbar-height;
    z-index: 5;
    height: calc(100% - #{$navbar-height});
    transition: border-radius $menu-collapse-time;

    .main-menu {
        width: $main-menu-width;
        height: calc(100% - #{$navbar-height});
        background: $foreground-color;
        z-index: 3;
        position: fixed;
        padding-top: 10px;
        padding-bottom: 10px;
        left: 0;

        .scroll {
            padding-right: unset;
            margin-right: unset;
            height: 100%;

            .ps__thumb-y {
                right: 0;
            }
        }

        &.default-transition {
            transition: transform $menu-collapse-time, border-radius $menu-collapse-time;
        }

        ul {
            margin-bottom: initial;

            li {
                position: relative;

                span {
                    text-align: center;
                    padding: 0 10px;
                    line-height: 14px;
                }

                a {
                    height: $main-menu-item-height;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    border-bottom: 1px solid $separator-color-light;
                    color: $primary-color;
                    transition: color $menu-collapse-time;
                    transition: background $menu-collapse-time;

                    &:hover,
                    &:focus {
                        color: $theme-color-1;
                    }
                }

                i {
                    font-size: 32px;
                    line-height: 42px;
                }

                &.active a {
                    color: $theme-color-1;
                }

                &.active:after {
                    content: " ";
                    background: $theme-color-1;
                    color: $foreground-color;
                    border-radius: 10px;
                    position: absolute;
                    width: 6px;
                    height: 90px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                }

                &:last-of-type {
                    a {
                        border-bottom: initial;
                    }
                }
            }
        }

        @include respond-below(xl) {
            width: $main-menu-width-lg;
            height: calc(100% - #{$navbar-height-lg});
        }

        @include respond-below(lg) {
            width: $main-menu-width-md;
            height: calc(100% - #{$navbar-height-md});
        }

        @include respond-below(sm) {
            width: $main-menu-width-xs;
            height: calc(100% - #{$navbar-height-xs});

            ul li i {
                font-size: 26px;
                line-height: 30px;
            }

            ul li a {
                height: $main-menu-item-height-mobile;
            }

            ul li.active:after {
                width: 3px;
                height: 60px;
            }
        }
    }

    .sub-menu {
        width: $sub-menu-width;
        background: $foreground-color;
        z-index: 2;
        position: fixed;
        left: $main-menu-width;
        border-left: 1px solid $separator-color-light;
        height: calc(100% - #{$navbar-height});
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0;

        @include depth(2);

        &.default-transition {
            transition: transform $menu-collapse-time;
        }

        .scroll {
            margin-right: unset;
            padding-right: unset;
            height: 100%;
            padding-top: 15px;
            padding-bottom: 15px;

            .ps__thumb-y {
                right: 0;
            }
        }

        ul {
            display: none;

            li {
                margin-left: 30px;
                margin-bottom: 0;
                position: relative;

                a {
                    font-size: 13px;
                    display: block;
                    padding: 8px 0;
                    margin-bottom: 10px;

                    span {
                        vertical-align: middle;
                        height: 16px;
                    }
                }

                i {
                    font-size: 1.3em;
                    margin-right: 10px;
                    color: $secondary-color;
                    vertical-align: middle;
                    display: inline-block;
                }

                &.active i,
                &.active a {
                    color: $theme-color-1;
                }

                &.active:after {
                    content: " ";
                    background: $theme-color-1;
                    border-radius: 10px;
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -16px;
                }
            }
        }

        ul.inner-level-menu {
            display: block !important;

            li {

                a,
                i {
                    color: $primary-color;
                }

                a:hover,
                &.active i,
                &.active a {
                    color: $theme-color-1;
                }

                &.active:after {
                    left: -22px;
                }
            }
        }

        .rotate-arrow-icon {
            i {
                transition: transform $animation-time-short;
                transform: rotate(0);
            }

            &.collapsed {
                i {
                    transform: rotate(-90deg);
                }
            }
        }

        @include respond-below(xl) {
            left: $main-menu-width-lg;
            width: $sub-menu-width-lg;
            height: calc(100% - #{$navbar-height-lg});
        }

        @include respond-below(lg) {
            left: $main-menu-width-md;
            width: $sub-menu-width-md;
            height: calc(100% - #{$navbar-height-md});
        }

        @include respond-below(sm) {
            left: $main-menu-width-xs;
            width: $sub-menu-width-xs;
            height: calc(100% - #{$navbar-height-xs});
        }
    }

    @include respond-below(xl) {
        padding-top: $navbar-height-lg;
        height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
        padding-top: $navbar-height-md;
        height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
        padding-top: $navbar-height-xs;
        height: calc(100% - #{$navbar-height-xs});
        @include depth(1);
    }
}

//Menu Config Main Menu Sub Menu
#app-container {

    &.sub-hidden .sub-menu,
    &.menu-sub-hidden .sub-menu,
    &.menu-hidden .sub-menu {
        transform: translateX(-$sub-menu-width);

        @include respond-below(xl) {
            transform: translateX(-$sub-menu-width-lg);
        }

        @include respond-below(lg) {
            transform: translateX(-$sub-menu-width-md);
        }

        @include respond-below(sm) {
            transform: translateX(-$sub-menu-width-xs);
        }
    }

    &.main-hidden .main-menu,
    &.menu-hidden .main-menu {
        transform: translateX(-$main-menu-width);

        @include respond-below(xl) {
            transform: translateX(-$main-menu-width-lg);
        }

        @include respond-below(lg) {
            transform: translateX(-$main-menu-width-md);
        }

        @include respond-below(sm) {
            transform: translateX(-$main-menu-width-xs);
        }
    }

    &.main-hidden.sub-hidden .sub-menu,
    &.menu-hidden .sub-menu {
        transform: translateX(-$sub-menu-width - $main-menu-width);

        @include respond-below(xl) {
            transform: translateX(-$sub-menu-width-lg - $main-menu-width-lg);
        }

        @include respond-below(lg) {
            transform: translateX(-$sub-menu-width-md - $main-menu-width-md);
        }

        @include respond-below(sm) {
            transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
        }
    }

    &.menu-main-hidden {
        .main-menu {
            width: 0;
            display: none;
        }

        .sub-menu {
            left: 0;
        }
    }

    //Mobile
    &.menu-mobile {
        .main-menu {
            transform: translateX(-$main-menu-width-xs);
        }

        .sub-menu {
            transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs - 50);
        }
    }

    //Show Temporary
    &.main-show-temporary {
        .main-menu {
            transform: translateX(0);
        }

        .sub-menu {
            transform: translateX(-$sub-menu-width);
        }

        @include respond-below(xl) {
            .main-menu {
                transform: translateX(0);
            }

            .sub-menu {
                transform: translateX(-$sub-menu-width-lg);
            }
        }

        @include respond-below(lg) {
            .main-menu {
                transform: translateX(0);
            }

            .sub-menu {
                transform: translateX(-$sub-menu-width-md);
            }
        }

        @include respond-below(sm) {
            .sub-menu {
                transform: translateX(-$sub-menu-width-xs);
            }
        }
    }

    &.sub-show-temporary,
    &.menu-mobile.sub-show-temporary,
    &.menu-main-hidden.menu-mobile.main-show-temporary {
        .sub-menu {
            transform: translateX(0);
        }
    }
}

/* 12.Apps*/
.app-menu {
    z-index: 1;
    @include depth(1);
    width: $app-menu-width;
    height: 100%;
    float: right;
    background: $foreground-color;
    transition: transform $menu-collapse-time;
    position: fixed;
    right: 0;
    top: $navbar-height;
    height: calc(100% - #{$navbar-height});

    @include respond-below(xl) {
        top: $navbar-height-lg;
        height: calc(100% - #{$navbar-height-lg});
    }

    @include respond-below(lg) {
        top: $navbar-height-md;
        transform: translateX($app-menu-width);
        height: calc(100% - #{$navbar-height-md});
    }

    @include respond-below(sm) {
        top: $navbar-height-xs;
        height: calc(100% - #{$navbar-height-xs});
    }

    .scroll {
        height: 100%;

        .ps__thumb-y {
            right: 0;
        }
    }

    &.shown {
        transform: translateX(0);
    }

    .app-menu-button {
        position: absolute;
        left: -28px;
        background: $foreground-color;
        top: $main-margin - 15;
        padding: 12px 8px 12px 5px;
        border-radius: 0.2rem;
        color: $secondary-color;
        box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
        font-size: 15px;
        line-height: 0;

        @include respond-below(xl) {
            top: $main-margin-lg - 14;
        }

        @include respond-below(lg) {
            top: $main-margin-md - 13;
        }

        @include respond-below(sm) {
            top: $main-margin-xs - 2;
        }
    }

    ul {
        li {
            margin-bottom: 5px;

            a {
                font-size: 13px;
                display: block;
                padding: 3px 0;

                &:hover i {
                    color: $theme-color-1;
                }
            }

            i {
                font-size: 1.2em;
                margin-right: 10px;
                color: $secondary-color;
                vertical-align: unset;

                @include respond-below(sm) {
                    font-size: 20px;
                }
            }

            &.active i,
            &.active a {
                color: $theme-color-1;
            }
        }
    }
}

.app-row {
    padding-right: $app-menu-width;

    @include respond-below(lg) {
        padding-right: 0;
    }
}

/* 13.Chat App*/
.chat-app {
    .scroll {
        padding-left: 10px;
        margin-left: -10px;
        padding-right: 10px;
        margin-right: -10px;

        .ps__thumb-y {
            right: 10px;
        }

        &>div>div.card:last-of-type {
            margin-bottom: 5px !important;
        }

        &>div>div.card {
            @include respond-below(sm) {
                margin-right: initial !important;
            }

            @include respond-below(md) {
                float: initial !important;
                clear: both !important;
            }
        }
    }

    .list-item-heading {
        @include respond-below(sm) {
            font-size: 0.9rem;
        }
    }

    .card .card-body {
        @include respond-below(sm) {
            padding: 0.75rem;
        }
    }

    .chat-text-left {
        padding-left: 64px;
    }

    .chat-text-right {
        padding-right: 64px;
    }
}

.chat-heading {
    .card-body {
        @include respond-below(sm) {
            padding: 0.5rem !important;
        }
    }
}

.chat-input-container {
    width: 100%;
    height: 90px;
    background: $input-background;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    @include depth(1);
    transition: padding $menu-collapse-time;

    padding-left: $main-menu-width + $sub-menu-width + $main-margin;
    padding-right: $app-menu-width + $main-margin;

    @include respond-below(xl) {
        padding-left: $main-menu-width-lg + $main-margin-lg;
        padding-right: $app-menu-width + $main-margin-lg;
    }

    @include respond-below(lg) {
        padding-left: $main-menu-width-md + $main-margin-md;
        padding-right: $main-margin-md;
    }

    @include respond-below(sm) {
        padding-right: 15px;
        padding-left: 15px;
    }

    input {
        border: initial;
        width: unset;
        height: 90px;
        padding-left: 0;
        display: inline-block;
    }

    .send-button {
        @include respond-below(md) {
            padding-left: 0.7rem;
            padding-right: 0.7rem;
        }
    }

    @include respond-below(md) {
        height: 80px;

        input {
            height: 80px;
        }

        .icon-button.large {
            width: 36px;
            height: 36px;
            font-size: 15px;
        }
    }

    @include respond-below(sm) {
        height: 60px;

        input {
            height: 60px;
        }

        .icon-button.large {
            width: 32px;
            height: 32px;
            font-size: 13px;
        }
    }
}

.menu-sub-hidden,
.sub-hidden {
    .chat-input-container {
        padding-left: $main-menu-width + $main-margin;

        @include respond-below(xl) {
            padding-left: $main-menu-width-lg + $main-margin-lg;
        }

        @include respond-below(lg) {
            padding-left: $main-menu-width-md + $main-margin-md;
        }

        @include respond-below(sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.menu-main-hidden,
.menu-hidden,
.main-hidden {
    .chat-input-container {
        padding-left: $main-margin;

        @include respond-below(xl) {
            padding-left: $main-margin-lg;
        }

        @include respond-below(lg) {
            padding-left: $main-margin-md;
        }

        @include respond-below(sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

/* 14.Survey App*/
.survey-app {
    .answers {
        .badge {
            color: $secondary-color;
        }

        input {
            padding-right: 70px;
        }
    }

    .question {
        &.edit-quesiton {
            .edit-mode {
                display: block;
            }

            .view-mode {
                display: none;
            }

            .view-button {
                display: inline-block;
            }

            .edit-button {
                display: none;
            }
        }

        &.view-quesiton {
            .edit-mode {
                display: none;
            }

            .view-mode {
                display: block;
            }

            .view-button {
                display: none;
            }

            .edit-button {
                display: inline-block;
            }
        }
    }

    .survey-question-types {
        .btn-group-icon {
            font-size: 21px;
            line-height: 28px;
        }

        .btn {
            padding-bottom: 0.3rem;
        }
    }
}

.input-icons {
    position: absolute;
    right: 0;
    top: 9px;
}

/* 15.Todo App*/
.heading-icon {
    color: $theme-color-1;
    font-size: 20px;
    line-height: inherit;
    margin-right: 5px;
    vertical-align: middle;
}

.heading-number {
    border: 1px solid $theme-color-1;
    padding: 4px;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 20px;
    width: 34px;
    height: 34px;
    text-align: center;
    color: $theme-color-1;
}

/* 16.Main*/
main {
    margin-left: $sub-menu-width + $main-menu-width + $main-margin;
    margin-top: $navbar-height + $main-margin - 15;
    margin-right: $main-margin;
    margin-bottom: $main-margin - 20;

    &.default-transition {
        transition: margin-left $menu-collapse-time;
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    @include respond-below(xl) {
        margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
        margin-right: $main-margin-lg;
        margin-top: $main-margin-lg + $navbar-height-lg -10;
        margin-bottom: 30px;
    }

    @include respond-below(lg) {
        margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
        margin-right: $main-margin-md;
        margin-top: $main-margin-md + $navbar-height-md -10;
        margin-bottom: 20px;
    }

    @include respond-below(sm) {
        margin-left: $main-margin-xs !important;
        margin-right: $main-margin-xs !important;
        margin-top: 10 + $navbar-height-xs !important;
        margin-bottom: 0;
    }

    @include respond-below(xs) {
        margin-bottom: 0;
    }
}

//Menu Config Main
#app-container {

    &.sub-hidden main,
    &.menu-sub-hidden main,
    &.menu-hidden main {
        margin-left: $main-menu-width + $main-margin;
    }

    &.main-hidden main,
    &.menu-hidden main {
        margin-left: $main-margin;
    }

    &.menu-main-hidden {
        main {
            margin-left: $main-margin + $sub-menu-width;
        }

        &.menu-hidden {
            main {
                margin-left: $main-margin;
            }
        }
    }

    @include respond-below(xl) {

        &.sub-hidden main,
        &.menu-sub-hidden main,
        &.menu-hidden main {
            margin-left: $main-menu-width-lg + $main-margin-lg;
        }

        &.main-hidden main,
        &.menu-hidden main {
            margin-left: $main-margin-lg;
        }

        &.menu-main-hidden {
            main {
                margin-left: $main-margin-lg + $sub-menu-width-lg;
            }

            &.menu-hidden {
                main {
                    margin-left: $main-margin-lg;
                }
            }
        }
    }

    @include respond-below(lg) {

        &.sub-hidden main,
        &.menu-sub-hidden main,
        &.menu-hidden main {
            margin-left: $main-menu-width-md + $main-margin-md;
        }

        &.main-hidden main,
        &.menu-hidden main {
            margin-left: $main-margin-md;
        }

        &.menu-main-hidden {
            main {
                margin-left: $main-margin-md + $sub-menu-width-md;
            }

            &.menu-hidden {
                main {
                    margin-left: $main-margin-md;
                }
            }
        }
    }
}

/* 17.Cards*/
.card {
    border: initial;
    background: $foreground-color;

    .card-header .card-icon {
        right: 5px;
        top: 5px;

        i {
            font-size: 12px;
            color: $separator-color;
        }
    }

    .card-header .handle {
        cursor: default;
    }

    border-radius: $border-radius;
    @include depth(1);

    .card-body {
        padding: 1.75rem;

        @include respond-below(xs) {
            padding: 1.5rem;
        }

        &.sm {
            padding: 1.25rem 1.75rem;
        }
    }

    .card-title {
        margin-bottom: 2rem;
    }
}

.card-img {
    border-radius: calc(0.25rem - 1px);
    height: 100%;
    object-fit: cover;
    max-height: 200px;
    width: unset;
}

.card-img-fluid {
    border-radius: calc(0.25rem - 1px);
    object-fit: cover;
}

.card-img-bottom {
    width: 100%;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.card-img-left {
    width: 100%;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    height: 100%;
    object-fit: cover;
}

.card-img-right {
    width: 100%;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    height: 100%;
    object-fit: cover;
}

.card-img-overlay {
    background: rgba(#000000, 0.5);
    padding: 1.75rem;

    @include respond-below(xs) {
        padding: 1rem;
    }
}

.card-top-buttons {
    padding: 1.3rem;
    right: 0;
    top: 0;

    @include respond-below(xs) {
        padding: 0.35rem;
    }
}

.card-header {
    border: initial;
    background: initial;
    padding-top: 0;
}

.img-thumbnail {
    border-radius: $border-radius;
    padding: 0;
    border: initial;
}

.list-thumbnail {
    border-radius: $border-radius;
    padding: 0;
    border: initial;
    height: auto;
    max-width: unset;
    height: 85px;
    object-fit: cover;
    width: unset;

    @include respond-below(md) {
        height: 80px;
    }

    @include respond-below(xs) {
        height: 70px;
    }

    &.responsive {
        @include respond-below(md) {
            width: unset;
            height: 135px;
        }

        @include respond-below(xs) {
            width: 110px !important;
            height: 100%;
        }

        @include respond-below(xxs) {
            width: 90px !important;
            height: 100%;
        }
    }

    &.small {
        height: 60px;
        font-size: 1rem;

        @include respond-below(md) {
            height: 55px;
        }

        @include respond-below(xs) {
            height: 50px;
        }
    }

    &.xsmall {
        height: 40px;
        font-size: 1rem;

        @include respond-below(md) {
            height: 40px;
        }

        @include respond-below(xs) {
            height: 40px;
        }
    }
}

.list-thumbnail-letters {
    width: 85px;
    height: 85px;
    background: $theme-color-1;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    color: $button-text-color;

    @include respond-below(md) {
        width: 80px;
        height: 80px;
    }

    @include respond-below(xs) {
        width: 70px;
        height: 70px;
    }

    &.small {
        width: 60px;
        height: 60px;
        font-size: 1rem;

        @include respond-below(md) {
            width: 55px;
            height: 55px;
        }

        @include respond-below(xs) {
            width: 50px;
            height: 50px;
        }
    }
}

/* 18.Dashboard*/

.dashboard-line-chart {
    height: 283px;
}

.dashboard-quick-post {
    min-height: 283px;
}

.dashboard-list-with-thumbs {
    height: 500px;
}

.dashboard-logs {
    height: 270px;
}

.dashboard-list-with-user {
    height: 270px;
}

.dashboard-donut-chart {
    height: 270px;
}

.dashboard-small-chart {
    height: 150px;

    .chart {
        height: 60px;
    }

    .lead {
        font-size: 1.4rem;
    }
}

.dashboard-small-chart-analytics {
    height: 180px;

    .chart {
        height: 80px;
    }

    .lead {
        font-size: 1.4rem;
    }
}

.dashboard-filled-line-chart {
    height: 340px;

    .chart {
        height: 200px;
    }
}

.dashboard-numbers .owl-nav,
.dashboard-numbers .owl-dots {
    display: none;
}

.dashboard-sq-banner {
    background-image: linear-gradient(to right top, $gradient-color-2, $gradient-color-3, $gradient-color-1);
    background-size: cover;
    height: 385px;
    transition: 0.5s;
    background-size: 350% auto;
    cursor: pointer;

    .card-body {
        width: 270px;
    }

    .lead {
        line-height: 2.3rem;
    }

    &:hover {
        background-position: right top;
    }
}

.dashboard-link-list {
    height: 385px;
}

.dashboard-progress {
    height: 385px;
}

.dashboard-top-rated {
    height: 300px;
}

.dashboard-top-rated {
    height: 300px;

    @include respond-below(md) {
        height: unset;
    }

    .glide {
        img {
            height: 120px;
            display: flex;
            object-fit: cover;
            width: 100%;
        }

        .react-rater {
            display: inline-block !important;
        }
    }

    .glide__slides .glide__slide {
        padding: initial;
        display: initial;
    }
}

.dashboard-search {
    height: 650px;
    // background: url("../img/login/plane.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $foreground-color;

    .card-body {
        padding: 120px 50px 30px 50px;

        @include respond-below(xl) {
            padding: 80px 30px 30px 30px;
        }
    }

    .form-container {
        height: 400px;
        border-radius: $border-radius;
        box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
        padding: 2rem;
        background-color: $foreground-color;
    }
}

.icon-cards-row {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;

    .glide__track {
        margin-left: -3px;
        margin-right: -3px;
    }

    .glide__slides {
        padding-top: 10px;
        padding-left: 3px;
        padding-right: 3px;
    }

    .glide__slides .glide__slide {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-body {
        padding: 2rem 0.5rem;
    }

    .card-text {
        color: $secondary-color;
        height: 30px;
        line-height: 26px;
    }

    .lead {
        color: $theme-color-1;
        margin-bottom: 0;
    }

    i {
        font-size: 38px;
        line-height: 66px;
        color: $theme-color-1;
    }

    .card {
        transition: box-shadow $animation-time-long;
        cursor: pointer;
    }

    @include respond-below(xl) {
        .card-text {
            height: 48px;
            line-height: 18px;
        }

        .lead {
            font-size: 1.6rem;
        }

        i {
            font-size: 32px;
            line-height: 47px;
        }
    }
}

.log-indicator {
    width: 13px;
    height: 13px;
    border: 2px solid $theme-color-1;
    border-radius: 14px;
    display: inline-block;
}

/* 19.Calendar */
.calendar {
    .alert-info {
        color: $theme-color-1;
        background-color: initial;
    }

    h2 {
        font-size: 14px;
        margin-top: 10px;
        display: inline-block;
    }
}

.fc-basic-view .fc-body .fc-row {
    min-height: 6em;

    @include respond-below(xs) {
        min-height: 3em;
    }
}

.fc-toolbar.fc-header-toolbar .btn-group .fc-next-button,
.fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button {
    outline: initial !important;
    box-shadow: initial !important;
    border-radius: 40px !important;
    text-align: center;
    min-width: 30px;
    height: 30px;
    padding: 0.55rem 0;
    background: $theme-color-1;
    color: $button-text-color;
    border: 1px solid $theme-color-1;
    line-height: 0.9 !important;
    font-size: 0.76rem;
    font-weight: normal !important;

    span {
        line-height: 0.9 !important;
        font-size: 0.76rem;
        font-weight: normal !important;
    }

    &:hover {
        background-color: transparent;
        border-color: rgba($theme-color-1, 0.9);
        color: $theme-color-1;
    }
}

.fc-toolbar.fc-header-toolbar .fc-prev-button {
    margin-right: 5px;
}

.fc-today-button {
    padding: 0.4em 1.3em !important;
    height: unset !important;
}

.fc-bootstrap4 .fc-day-top .fc-day-number {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
}

.fc-bootstrap4 td.fc-today {
    background: initial;

    .fc-day-number {
        background: $theme-color-1;
        color: $button-text-color;
    }
}

.fc-day-grid-event .fc-content {
    color: $button-text-color;
}

.fc-day-grid-container {
    height: 100%;
    overflow: visible;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
    padding: 5px;
}

.fc-view,
.fc-view>table {
    border-top: initial;
}

.fc table {
    border: initial;
}

.fc .table-bordered thead th,
.fc .table-bordered thead td {
    border-top: initial;
    border-bottom: initial;
    border-left: initial;
}

.fc td:first-of-type,
.fc th:first-of-type {
    border-left: initial;
    border-bottom: initial;
}

.fc td:last-of-type,
.fc th:last-of-type {
    border-right: initial;
    border-bottom: initial;
}

.fc-body .fc-row:last-of-type td {
    border-bottom: initial;
}

.fc tbody>tr>td.fc-widget-content:first-of-type {
    border-left: initial;
    border-right: initial;
    border-bottom: initial;
}

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
    border-color: $separator-color-light !important;
}

.fc-day-header {
    color: $theme-color-1;

    span {
        padding: 10px;
        display: inline-block;
    }
}

.fc-event {
    border: initial;
}

.fc-event,
.fc-event-dot {
    background-color: $theme-color-2;
    color: $foreground-color !important;
    padding: 1px 6px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
}

/* 20.Datatable */
.dataTables_wrapper {
    overflow: hidden;
}

table.dataTable td {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $separator-color-light;
    outline: initial !important;
}

table.dataTable tr:last-of-type td {
    border-bottom: initial;
}

table.dataTable {
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

table p,
table h6 {
    margin-bottom: initial;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-top: 10px;
    padding-bottom: 10px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 1.5em;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 2em;
}

.dataTables_wrapper .paginate_button.previous {
    margin-right: 15px;
}

.dataTables_wrapper .paginate_button.next {
    margin-left: 15px;
}

div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 25px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center;
}

.dataTables_wrapper .paginate_button.page-item {
    padding-left: 10px;
    padding-right: 10px;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    top: unset;
    box-shadow: initial;
    background-color: $theme-color-1;
    font-size: 12px;
}

.data-table-rows {
    .dataTables_wrapper {
        overflow: initial;
    }

    table {
        border-spacing: 0px 1rem;

        tbody tr {
            @include depth(1);
        }

        tbody tr.selected {
            @include depth(2);
        }

        tbody tr.child {
            td {
                padding: 0.75rem 2rem;

                li {
                    padding: 0 !important;
                }
            }
        }

        td,
        th {
            padding: 1.5rem;
            border: initial;
        }

        td {
            background: $foreground-color;
        }

        th.empty {

            &:before,
            &:after {
                content: "";
            }
        }

        .data-table-rows-check {
            text-align: right;
            pointer-events: none;
        }
    }

    table.dataTable thead .sorting:before,
    table.dataTable thead .sorting_asc:before,
    table.dataTable thead .sorting_asc_disabled:before,
    table.dataTable thead .sorting_desc:before,
    table.dataTable thead .sorting_desc_disabled:before {
        right: initial;
        left: 0.5em;
    }

    table.dataTable thead .sorting:after,
    table.dataTable thead .sorting_asc:after,
    table.dataTable thead .sorting_asc_disabled:after,
    table.dataTable thead .sorting_desc:after,
    table.dataTable thead .sorting_desc_disabled:after {
        right: initial;
        left: 0;
    }
}

.data-tables-hide-filter .view-filter {
    display: none;
}

/* 21.Alerts */
.alert {
    border-radius: 0;
}

.alert-primary {
    color: $theme-color-1;
    background-color: mix($theme-color-1, $background-color, 20%);
    border-color: rgba($theme-color-1, 0.1);
}

.alert-secondary {
    color: $theme-color-2;
    background-color: mix($theme-color-2, $background-color, 20%);
    border-color: rgba($theme-color-2, 0.1);
}

.alert-success {
    color: $success-color;
    background-color: mix($success-color, $background-color, 20%);
    border-color: rgba($success-color, 0.1);
}

.alert-info {
    color: $info-color;
    background-color: mix($info-color, $background-color, 20%);
    border-color: rgba($info-color, 0.1);
}

.alert-warning {
    color: $warning-color;
    background-color: mix($warning-color, $background-color, 20%);
    border-color: rgba($warning-color, 0.1);
}

.alert-danger {
    color: $error-color;
    background-color: mix($error-color, $background-color, 20%);
    border-color: rgba($error-color, 0.1);
}

.alert-light {
    color: $light-btn-background;
    background-color: mix($light-btn-background, $background-color, 20%);
    border-color: rgba($light-btn-background, 0.1);
}

.alert-dark {
    color: $dark-btn-background;
    background-color: mix($dark-btn-background, $background-color, 20%);
    border-color: rgba($dark-btn-background, 0.1);
}

.alert-dismissible .close,
button.close {
    text-shadow: initial;
}

.alert *[data-notify="title"] {
    display: block;
    font-size: 0.9rem;
}

div[data-notify="container"] {
    padding: 18px;
}

/* 22.Forms */
.form-check-label,
.custom-control-label {
    line-height: 1.5rem;
    padding-top: 1px;
}

.form-control,
.bootstrap-tagsinput {
    border-radius: $border-radius;
    outline: initial !important;
    box-shadow: initial !important;
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
    line-height: 1;
    border: 1px solid $separator-color;
    background: $input-background;
    color: $primary-color;
    border-color: $separator-color;
    height: calc(2em + 0.8rem);
}

.form-control-sm {
    padding: 0.5rem;
    font-size: 0.75rem;
    height: auto !important;
}

.form-control-lg {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    height: auto !important;
}

select {
    outline: initial !important;
    box-shadow: initial !important;
}

textarea.form-control {
    line-height: 1.5;
}

@keyframes autofill {
    to {
        color: $primary-color;
        background: transparent;
    }
}

input:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
}

input:-webkit-autofill {
    -webkit-text-fill-color: $primary-color !important;
}

.bootstrap-tagsinput input {
    color: $primary-color;
}

.has-float-label label::after,
.has-float-label>span::after,
.has-float-label>span::after {
    background: $input-background;
}

.has-float-label>div.error::after {
    background: initial;
}

.form-control:focus {
    background: $input-background;
    color: $primary-color;
}

.bootstrap-tagsinput {
    width: 100%;
}

.bootstrap-tagsinput input {
    padding: 0;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
    margin-top: -1px;
    display: inline-block;
    vertical-align: top;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: initial;
}

.bootstrap-tagsinput .tag {
    background: $theme-color-1;
    border-radius: 15px;
    padding: 3px 8px;
    margin-bottom: 0px;
    display: inline-block;
    font-size: 12px;
}

.form-control:focus,
.bootstrap-tagsinput.focus {
    border-color: rgba($theme-color-1, 0.6);
}

.bootstrap-tagsinput {
    min-height: calc(2em + 0.6rem);
    height: auto;
}

.bootstrap-tagsinput .tag {
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
}

.bootstrap-tagsinput input {
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: rgba($primary-color, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
    background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='" + encodecolor($button-text-color ) + "' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='" + encodecolor($button-text-color ) + "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='" + encodecolor($button-text-color ) + "'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
    outline: initial !important;
    box-shadow: initial !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
    background-image: initial;
}

.custom-control-input {
    left: 1px;
    top: 3px;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
}

.custom-control-label::before {
    border: 1px solid $muted-color !important;
    background: initial !important;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: $theme-color-1 !important;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: $theme-color-1 !important;
    box-shadow: initial !important;
    border: 1px solid $theme-color-1 !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: $theme-color-1 !important;
    box-shadow: initial !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: $theme-color-1 !important;
    box-shadow: initial !important;
}

.custom-control-label::after,
.custom-control-label::before {
    box-shadow: initial !important;
}

.custom-control-label::before {
    top: 0.25rem;
}

.custom-control-label::after {
    top: 0.25rem;
}

.btn.rotate-icon-click i {
    transition: transform 0.5s;
}

.btn.rotate i {
    transform: rotate(180deg);
}

.btn .custom-control-label::before {
    border: 1px solid $button-text-color !important;
    background: transparent !important;
}

.btn .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid $button-text-color !important;
    background: transparent !important;
}

.btn-group-icon {
    line-height: 22px;
}

.invalid-tooltip,
.valid-tooltip,
div.error {
    border-radius: $border-radius;
    padding: 0.5rem 1rem;
    font-size: 0.76rem;
    color: $primary-color;
    background: $foreground-color;
    border: 1px solid $theme-color-1;
    text-align: center;
    width: unset !important;
    position: absolute;
    z-index: 4;
    margin-top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1.5;
    @include depth(1);

    &::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -2.5px;
        margin-left: 50%;
        width: 10px;
        height: 5px;
        border-bottom: solid 5px $theme-color-1;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
    }

    &::after {
        content: "";
        position: absolute;
        top: -4px;
        left: -2.5px;
        margin-left: 50%;
        width: 10px;
        height: 5px;
        border-bottom: solid 5px $foreground-color;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
    }
}

.tooltip-right-bottom .invalid-tooltip,
.tooltip-right-bottom .valid-tooltip,
.tooltip-right-bottom div.error {
    left: initial;
    right: 0;
    transform: translateX(0);
    top: 80%;

    &::before,
    &::after {
        left: initial;
        right: 25px;
        margin-left: 0;
    }
}

.tooltip-center-bottom .invalid-tooltip,
.tooltip-center-bottom .valid-tooltip,
.tooltip-center-bottom div.error {
    left: 50%;
    right: initial;
    transform: translateX(-50%) translateY(50%);
    top: 80%;
}

.tooltip-left-bottom .invalid-tooltip,
.tooltip-left-bottom .valid-tooltip,
.tooltip-left-bottom div.error {
    left: 0;
    right: initial;
    transform: translateX(0);
    top: 80%;

    &::before,
    &::after {
        left: 25px;
        right: initial;
        margin-left: 0;
    }
}

.tooltip-center-top .invalid-tooltip,
.tooltip-center-top .valid-tooltip,
.tooltip-center-top div.error {
    bottom: 80%;
    transform: translateX(-50%) translateY(50%);
    top: initial;

    &::before {
        content: "";
        position: absolute;
        top: initial;
        bottom: -5px;
        border-top: solid 5px $theme-color-1;
        border-bottom: initial;
    }

    &::after {
        content: "";
        position: absolute;
        top: initial;
        bottom: -4px;
        border-top: solid 5px $foreground-color;
        border-bottom: initial;
    }
}

.tooltip-right-top .invalid-tooltip,
.tooltip-right-top .valid-tooltip,
.tooltip-right-top div.error {
    bottom: 80%;
    transform: translateX(0) translateY(50%);
    top: initial;
    left: initial;
    right: 0;

    &::before {
        content: "";
        position: absolute;
        top: initial;
        bottom: -5px;
        border-top: solid 5px $theme-color-1;
        border-bottom: initial;
        left: initial;
        right: 25px;
        margin-left: 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: initial;
        bottom: -4px;
        border-top: solid 5px $foreground-color;
        border-bottom: initial;
        left: initial;
        right: 25px;
        margin-left: 0;
    }
}

.tooltip-left-top .invalid-tooltip,
.tooltip-left-top .valid-tooltip,
.tooltip-left-top div.error {
    bottom: 80%;
    transform: translateX(0) translateY(50%);
    top: initial;
    left: 0;
    right: initial;

    &::before {
        content: "";
        position: absolute;
        top: initial;
        bottom: -5px;
        border-top: solid 5px $theme-color-1;
        border-bottom: initial;
        left: 25px;
        right: initial;
        margin-left: 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: initial;
        bottom: -4px;
        border-top: solid 5px $foreground-color;
        border-bottom: initial;
        left: 25px;
        right: initial;
        margin-left: 0;
    }
}

.tooltip-label-right .invalid-tooltip,
.tooltip-label-right .valid-tooltip,
.tooltip-label-right div.error {
    transform: translateX(0) translateY(-50%);
    top: 16px;
    left: 50px;

    &::before {
        content: "";
        position: absolute;
        left: -5px;
        right: initial;
        margin-left: 0;
        border: initial;
        border-top: solid 5px transparent;
        border-bottom: solid 5px transparent;
        border-right: solid 5px $theme-color-1;
        bottom: initial;
        top: 12px;
        width: 5px;
    }

    &::after {
        content: "";
        position: absolute;
        top: initial;
        left: -4px;
        right: initial;
        margin-left: 0;
        width: 5px;
        border: initial;
        border-top: solid 5px transparent;
        border-bottom: solid 5px transparent;
        border-right: solid 5px $foreground-color;
        bottom: initial;
        top: 12px;
        width: 5px;
    }
}

.tooltip-label-right {
    .error-l-0 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 0px;
        }
    }

    .error-l-25 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 25px;
        }
    }

    .error-l-50 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 50px;
        }
    }

    .error-l-75 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 75px;
        }
    }

    .error-l-100 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 100px;
        }
    }

    .error-l-125 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 125px;
        }
    }

    .error-l-150 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 150px;
        }
    }

    .error-l-175 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 175px;
        }
    }

    .error-l-200 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 200px;
        }
    }

    .error-l-225 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 225px;
        }
    }

    .error-l-250 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 250px;
        }
    }

    .error-l-275 {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            left: 275px;
        }
    }

    .error-t-negative {

        .invalid-tooltip,
        .valid-tooltip,
        div.error {
            top: -10px;
        }
    }
}

.form-group.is-invalid {
    .invalid-tooltip {
        display: block;
    }
}

// .valid-tooltip,
// .invalid-tooltip, div.error {
//   border-radius: 15px;
//   padding: 0.5rem 1rem;
//   left: 50%;
//   transform: translateX(-50%);
//   font-size: 0.76rem;
//   position: absolute;
//   color: $button-text-color;
// }

// .valid-tooltip {
//   background-color: $success-color;
//   margin-top: -0.2rem;
// }

// .invalid-tooltip, div.error {
//   background-color: $error-color;
//   margin-top: -0.2rem;
// }

// .valid-tooltip:after {
//   content: "";
//   position: absolute;
//   top: -5px;
//   left: -2.5px;
//   margin-left: 50%;
//   width: 10px;
//   height: 5px;
//   border-bottom: solid 5px $success-color;
//   border-left: solid 5px transparent;
//   border-right: solid 5px transparent;
// }

// .invalid-tooltip:after, div.error:after {
//   content: "";
//   position: absolute;
//   top: -5px;
//   left: -2.5px;
//   margin-left: 50%;
//   width: 10px;
//   height: 5px;
//   border-bottom: solid 5px $error-color;
//   border-left: solid 5px transparent;
//   border-right: solid 5px transparent;
// }

.valid-icon {
    position: absolute;
    bottom: 8px;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0;
    font-size: 0.76rem;
    line-height: 1;
    color: $foreground-color;
    border-radius: 0.2rem;
    right: 4px;
    color: $success-color;
}

.invalid-icon {
    position: absolute;
    bottom: 2px;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.5rem;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1;
    color: $foreground-color;
    border-radius: 0.2rem;
    right: 4px;
    color: $error-color;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: $separator-color !important;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: $separator-color !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: rgba($theme-color-1, 0.6);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: rgba($theme-color-1, 0.6);
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    background: initial !important;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: $primary-color;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    background-color: $theme-color-1 !important;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: $primary-color;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    background: initial !important;
}

.select2-container--bootstrap .select2-selection--single {
    border-radius: $border-radius !important;
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
    line-height: 1;
    height: calc(2em + 0.8rem);
}

.select2-dropdown,
.select2-container--bootstrap .select2-selection.form-control {
    background: $input-background;
}

.select2-container--bootstrap .select2-results__option[aria-selected="true"] {
    background: $input-background;
    color: $primary-color;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
    color: $primary-color;
    padding-top: 5px;
}

.input-group-text {
    background-color: rgba($input-background, 0.1);
    border-color: $separator-color;
    color: $primary-color;
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    line-height: 1 !important;
}

.select2-container--bootstrap .select2-selection {
    border: 1px solid $separator-color;
}

.select2-container--bootstrap .select2-selection--multiple {
    height: calc(2em + 0.8rem) !important;
    font-size: 0.8rem;
    padding: 0.5rem 75rem;
    min-height: initial !important;
    padding: 0.5rem 0.25rem;
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    min-height: initial !important;
    height: initial;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
    border-color: rgba($theme-color-1, 0.7);
}

.select2-container--bootstrap .select2-dropdown {
    border-color: rgba($theme-color-1, 0.7);
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
    background-color: $theme-color-1;
    color: $button-text-color;
}

.select2-dropdown,
.select2-container--bootstrap .select2-selection.form-control {
    border-radius: $border-radius !important;
}

.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
    border-radius: $border-radius !important;
    border: 1px solid $secondary-color;
    outline: initial;
    background: $input-background;
    color: $primary-color;
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    outline: initial;
    background: $input-background;
    color: $primary-color;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    border-radius: $border-radius;
    background: $foreground-color;
    color: $primary-color;
    border-color: $separator-color;
    margin: 0 0 0 8px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice__remove:hover {
    outline: initial;
    text-shadow: initial;
    color: $theme-color-1;
}

.typeahead.dropdown-menu {
    width: 100%;
    border-radius: $border-radius !important;

    .dropdown-item {
        padding: 0.5rem 0.75rem !important;
    }

    & .active a {
        background-color: $theme-color-1;
        color: $button-text-color;
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 10px;
    height: calc(2em);
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    padding-top: 3px;
    padding-left: 8px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    margin: 2px 0 2px 5px;
}

.select2-container--bootstrap .select2-selection--multiple {
    height: unset !important;
    padding: 0.4rem 0.25rem;
}

.datepicker-dropdown {
    transform: translateY(-150px);

    @include respond-below(xl) {
        transform: translateY(-130px);
    }

    @include respond-below(lg) {
        transform: translateY(-110px);
    }

    @include respond-below(sm) {
        transform: translateY(-85px);
    }
}

.datepicker-dropdown:before {
    border-bottom-color: rgba($primary-color, 0.2);
}

.datepicker table tr td.range {
    color: $button-text-color;
    background-color: $theme-color-1;
    border-color: $theme-color-1;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
    color: $button-text-color;
    background-color: $theme-color-1;
    border-color: $theme-color-1;
}

.datepicker-dropdown.datepicker-orient-top:before {
    border-top: 7px solid rgba($primary-color, 0.2);
}

.datepicker table tr td,
.datepicker table tr th {
    color: $primary-color;
}

.datepicker-dropdown.datepicker-orient-top:after {
    border-top-color: $input-background;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    border-bottom-color: $input-background;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
    background: $separator-color;
}

.datepicker table tr td,
.datepicker table tr th {
    width: 35px;
    height: 35px;
    border-radius: $border-radius;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active {
    color: $foreground-color;
    background-color: $theme-color-1;
    border-color: $theme-color-1;
}

.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active:active:hover {
    color: $foreground-color;
    background-color: darken($theme-color-1, 10%);
    border-color: darken($theme-color-1, 10%);
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
    background-color: darken($theme-color-1, 10%);
    border-color: darken($theme-color-1, 10%);
}

.datepicker.datepicker-inline,
.datepicker.datepicker-inline table {
    width: 100%;
}

.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
    background: $separator-color;
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
    background: $separator-color;
}

.dropzone {
    min-height: 115px !important;
    border: 1px solid $separator-color !important;
    background: $input-background !important;
    padding: 10px 10px !important;
    border-radius: $border-radius !important;
    color: $primary-color !important;
    height: auto;

    .img-thumbnail {
        height: 58px !important;
        width: 100% !important;
        object-fit: cover !important;
    }
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    position: relative;
    transform: translateY(-50%);
    top: 50% !important;
    margin: 0;
}

.dropzone.dz-clickable .dz-message span {
    top: 50px !important;
}

.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
    width: 260px;
    height: 60px;
    min-height: unset;
    border: 1px solid $separator-color !important;
    border-radius: $border-radius !important;
    background: $foreground-color !important;
    color: $primary-color !important;

    .preview-container {
        transition: initial !important;
        animation: initial !important;
        margin-left: 0;
        margin-top: 0;
        position: relative;
        width: 100%;
        height: 100%;

        i {
            color: $theme-color-1;
            font-size: 20px;
            position: absolute;
            left: 50%;
            top: 29px;
            transform: translateX(-50%) translateY(-50%) !important;
            height: 22px;
        }
    }

    strong {
        font-weight: normal;
    }

    .remove {
        position: absolute;
        right: 5px;
        top: 5px;
        color: $theme-color-1 !important;
    }

    .dz-details {
        position: static;
        display: block;
        opacity: 1;
        text-align: left;
        min-width: unset;
        z-index: initial;
        color: $primary-color !important;
    }

    .dz-error-mark {
        color: $button-text-color !important;
        top: 15px;
        left: 25px;
        margin-left: 0;
        margin-top: 0;

        span {
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor($theme-color-1 ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor($foreground-color ) + ";' d='M13.13,19.35V6.17a.88.88,0,1,1,1.75,0V19.35Z'/%3E%3Crect style='fill:" + encodecolor($foreground-color ) + ";' x='13.13' y='21.07' width='1.75' height='1.64'/%3E%3C/svg%3E");
            width: 28px;
            height: 28px;
        }
    }

    .dz-success-mark {
        color: $button-text-color;
        top: 15px;
        left: 25px;
        margin-left: 0;
        margin-top: 0;

        span {
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'%3E%3Cpath style='fill:" + encodecolor($theme-color-1 ) + ";' d='M4.1,23.9A13.51,13.51,0,0,1,0,14,13.52,13.52,0,0,1,4.1,4.1,13.52,13.52,0,0,1,14,0a13.52,13.52,0,0,1,9.9,4.1A13.52,13.52,0,0,1,28,14a13.51,13.51,0,0,1-4.1,9.9A13.52,13.52,0,0,1,14,28,13.52,13.52,0,0,1,4.1,23.9Z'/%3E%3Cpath style='fill:" + encodecolor($foreground-color ) + ";' d='M20.14,8.81A.77.77,0,0,1,21.2,9a.81.81,0,0,1,.25.61.83.83,0,0,1-.25.62L12.48,19l-.11.1a.82.82,0,0,1-1.23,0L6.79,14.74l-.11-.16a.49.49,0,0,1-.08-.18,1.06,1.06,0,0,1,0-.19.61.61,0,0,1,0-.19,1.16,1.16,0,0,1,0-.18,1.26,1.26,0,0,1,.08-.18,1,1,0,0,1,.11-.15.87.87,0,0,1,1.26,0l3.69,3.7L19.94,9A.72.72,0,0,1,20.14,8.81Z'/%3E%3C/svg%3E");
            width: 28px;
            height: 28px;
        }
    }

    .dz-progress {
        width: 84%;
        margin-left: 0;
        margin-top: 0;
        right: 0;
        height: 5px !important;
        left: 15px;

        .dz-upload {
            width: 100%;
            background: $theme-color-1 !important;
        }
    }

    .dz-error-message {
        border-radius: 15px;
        background: $error-color !important;
        top: 60px;

        &:after {
            border-bottom: 6px solid $error-color !important;
        }
    }

    [data-dz-name] {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        display: inline-block;
        overflow: hidden;
    }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
    display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
    .preview-icon {
        display: none;
    }

    .dz-error-mark,
    .dz-success-mark {
        color: $theme-color-1 !important;
    }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
    display: none;
}

@-webkit-keyframes pulse-inner {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.8, 1);
        -moz-transform: scale(0.8, 1);
        -ms-transform: scale(0.8, 1);
        -o-transform: scale(0.8, 1);
        transform: scale(0.8, 1);
    }

    20% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes pulse-inner {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.8, 1);
        -moz-transform: scale(0.8, 1);
        -ms-transform: scale(0.8, 1);
        -o-transform: scale(0.8, 1);
        transform: scale(0.8, 1);
    }

    20% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes pulse-inner {
    0% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.8, 1);
        -moz-transform: scale(0.8, 1);
        -ms-transform: scale(0.8, 1);
        -o-transform: scale(0.8, 1);
        transform: scale(0.8, 1);
    }

    20% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse-inner 3s ease infinite;
    -moz-animation: pulse-inner 3s ease infinite;
    -ms-animation: pulse-inner 3s ease infinite;
    -o-animation: pulse-inner 3s ease infinite;
    animation: pulse-inner 3s ease infinite;
}

select.form-control {
    padding: 0 0.75rem;
}

.custom-switch {
    padding-left: initial;
}

.custom-switch .custom-switch-input+.custom-switch-btn {
    background: $separator-color;
}

.custom-switch.custom-switch-primary .custom-switch-input:checked+.custom-switch-btn {
    background: $theme-color-1;
    border: 1px solid $theme-color-1;
}

.custom-switch.custom-switch-secondary .custom-switch-input:checked+.custom-switch-btn {
    background: $theme-color-2;
    border: 1px solid $theme-color-2;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input+.custom-switch-btn {
    border: 1px solid $separator-color;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn {
    background: $input-background;
    border: 1px solid $theme-color-1;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
    background: $theme-color-1;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input+.custom-switch-btn {
    border: 1px solid $separator-color;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn {
    background: $input-background;
    border: 1px solid $theme-color-2;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
    background: $theme-color-2;
}

.input-group-text {
    border-radius: $border-radius;
}

.custom-switch .custom-switch-input+.custom-switch-btn:after {
    background: $input-background;
}

.custom-switch .custom-switch-input+.custom-switch-btn {
    border-color: $separator-color;
}

.noUi-target {
    background: $input-background;
    border-color: $separator-color;
    box-shadow: initial;
}

.noUi-tooltip {
    background: $input-background;
    color: $primary-color;
    border-color: $separator-color;
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    border-radius: $border-radius;
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    border-radius: $border-radius;
}

.custom-select {
    border-radius: $border-radius;
    padding: 0.75rem;
    font-size: 0.8rem;
    line-height: 1;
    height: calc(2em + 0.8rem);
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    outline: initial !important;
    box-shadow: initial !important;
}

.custom-select:focus {
    border-color: $theme-color-1;
}

.custom-file-input {
    box-shadow: initial !important;
}

.custom-file-label {
    box-shadow: initial !important;
    border-radius: $border-radius;
    height: calc(2em + 0.8rem);
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background: $foreground-color;
    border-color: $separator-color;
}

.custom-file {
    height: calc(2em + 0.8rem);
}

.custom-file-label:focus,
.custom-file-input:focus {
    border-color: rgba($theme-color-1, 0.6);
}

.custom-file-input:focus~.custom-file-label {
    border-color: rgba($theme-color-1, 0.6);
    box-shadow: initial;
}

.custom-file-input:focus~.custom-file-label::after {
    border-color: rgba($theme-color-1, 0.6);
}

.custom-file-label:after,
.input-group>.custom-select:not(:first-child),
.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:first-child),
.input-group>.form-control:not(:last-child) {
    outline: initial !important;
    box-shadow: initial !important;
}

.custom-file-label::after {
    height: calc(2em + 0.8rem);
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    color: $primary-color;
    border-color: $separator-color;
    background: transparent;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    background: $foreground-color;
    color: $primary-color;
    border-color: $separator-color;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    background: $foreground-color;
    color: $primary-color;
    border-color: $separator-color;
}

.jumbotron {
    background: initial;
}

.has-top-label label,
.has-top-label>span,
.has-float-label label,
.has-float-label>span {
    color: rgba($primary-color, 0.7);
}

/* 23.Form Wizard */
.sw-main {
    box-shadow: initial;

    &>ul.step-anchor {
        padding: 0 0.75rem;
        background: initial;
    }

    .step-content {
        background: initial;
    }

    &.sw-theme-default>ul.step-anchor>li>a {
        font-weight: 700;
    }

    &.sw-theme-default>ul.step-anchor>li>a::after {
        content: initial !important;
    }

    &.sw-theme-default>ul.step-anchor>li.active>a::before {
        content: " ";
        position: absolute;
        background: $theme-color-1;
        width: calc(100% - 1rem);
        border-radius: 10px;
        height: 5px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &>.btn-toolbar.sw-toolbar.sw-toolbar-bottom {
        background: initial;
        padding: 1.75rem;

        @include respond-below(xs) {
            padding: 1.5rem;
        }
    }

    &>ul.step-anchor>li.active>a {
        color: $theme-color-1 !important;
    }

    &>ul.step-anchor>li>a,
    &>ul.step-anchor>li>a:hover,
    &>ul.step-anchor>li>a:focus {
        color: $separator-color;
    }

    .step-content {
        padding: initial;
    }

    &>ul.step-anchor>li.done>a {
        color: $primary-color !important;
    }

    &.sw-theme-dots>ul.step-anchor>li>a:before,
    &.sw-theme-check>ul.step-anchor>li>a:before {
        left: 50%;
        transform: translateX(-50%);
        background: $separator-color-light;
        bottom: 5px;
        width: 24px;
        height: 24px;
    }

    &.sw-theme-dots>ul.step-anchor>li>a:after,
    &.sw-theme-check>ul.step-anchor>li>a:after {
        left: 50%;
        transform: translateX(-50%);
        width: 12px;
        height: 12px;
        bottom: 3px;
        background: $foreground-color;
    }

    &.sw-theme-dots>ul.step-anchor>li.done>a:after,
    &.sw-theme-check>ul.step-anchor>li.done>a:after {
        background: $theme-color-1;
    }

    &.sw-theme-dots>ul.step-anchor>li.active>a:after {
        background: initial;
        border: 2px solid $theme-color-1;
    }

    &.sw-theme-dots>ul.step-anchor:before,
    &.sw-theme-check>ul.step-anchor:before {
        background: $separator-color-light;
        height: 1px;
        width: calc(100% - 3rem);
        left: 50%;
        transform: translateX(-50%);
        top: 67px;
    }

    &.sw-theme-check>ul.step-anchor>li.active>a:after {
        background: $theme-color-1;
    }

    &.sw-main.sw-theme-check>ul.step-anchor>li.done>a:after {
        background: initial;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='" + encodecolor($theme-color-1 ) + "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }

    &>ul.step-anchor>li.clickable>a:hover {
        color: $theme-color-2 !important;
    }

    &>.btn-toolbar.sw-toolbar.sw-toolbar-bottom .btn-group {
        margin-right: 0 !important;
    }

    .custom-toolbar {
        height: auto !important;
        min-height: auto !important;
    }
}

.sw-theme-check>ul.step-anchor>li>a,
.sw-theme-check>ul.step-anchor:before,
.sw-theme-dots>ul.step-anchor>li>a,
.sw-theme-dots>ul.step-anchor:before {
    z-index: 1;
}

#smartWizardValidation {
    .card-body>div {
        height: 180px;
    }
}

/* 24.Slider(Range) */
.noUi-connect {
    background: $theme-color-1;
}

.noUi-connects {
    border-radius: $border-radius;
}

.noUi-handle:after,
.noUi-handle:before {
    content: "";
    display: none;
}

.noUi-handle {
    outline: initial;
    box-shadow: initial !important;
}

.noUi-horizontal {
    height: 8px;
}

.noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: -7px;
    border-color: $separator-color;
    background: $foreground-color;
}

.noUi-horizontal .noUi-tooltip {
    top: 120%;
    bottom: unset;
    padding-left: 10px;
    padding-right: 10px;
}

/* 25.Navs */
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: $foreground-color;
    background-color: $theme-color-1;
}

.nav-pills .nav-link {
    border-radius: 50px;
}

.nav-link {
    @include respond-below(sm) {
        padding: 0.5rem 0.5rem;
    }

    &.active {
        color: $theme-color-1;
    }
}

.page-item {
    padding: 10px;

    @include respond-below(sm) {
        padding: 3px;
    }

    .page-link {
        outline: initial !important;
        box-shadow: initial !important;
        line-height: 1.7;
        border-radius: 40px !important;
        min-width: 38px;
        text-align: center;
        height: 38px;
        padding: 0.5rem 0;

        @include respond-below(xs) {
            min-width: 30px;
            height: 30px;
            line-height: 0.9;
            font-size: 0.76rem;
        }

        &.next {
            background: $theme-color-1;
            color: $button-text-color;
            border: 1px solid $theme-color-1;
        }

        &.prev,
        &.prev {
            background: $theme-color-1;
            border: 1px solid $theme-color-1;
            color: $button-text-color;
        }

        &.first,
        &.last {
            background: transparent;
            color: $theme-color-1;
            border: 1px solid $theme-color-1;
            border-radius: 30px;

            &:hover {
                background: $theme-color-1;
                color: $foreground-color;
                border: 1px solid $theme-color-1;
            }
        }

        &:hover {
            background-color: transparent;
            border-color: lighten($theme-color-1, 10%);
            color: $theme-color-1;
        }
    }

    &.active .page-link {
        background: transparent;
        border: 1px solid $theme-color-1;
        color: $theme-color-1;
    }

    &.disabled .page-link {
        border-color: $separator-color;
        color: $separator-color;
        background: transparent;
    }
}

.page-link {
    background-color: transparent;
    border-color: transparent;
    color: $primary-color;
}

.btn-sm.page-link {
    padding: 0.5rem 0.5rem;
}

.pagination-lg {
    .page-item {
        padding: 15px;

        @include respond-below(sm) {
            padding: 3px;
        }

        .page-link {
            min-width: 50px;
            height: 50px;

            @include respond-below(sm) {
                min-width: 38px;
                height: 38px;
                padding: 0.55rem 0;
                line-height: 1.5;
                font-size: 0.8rem;
            }

            @include respond-below(xs) {
                min-width: 30px;
                height: 30px;
                line-height: 0.9;
                font-size: 0.76rem;
            }
        }
    }
}

.pagination-sm {
    .page-item {
        padding: 6px;

        @include respond-below(sm) {
            padding: 3px;
        }

        .page-link {
            min-width: 30px;
            height: 30px;
            line-height: 1;
            font-size: 0.76rem;
        }
    }
}

/* 26.Tooltip and popover */
.popover {
    border-radius: $border-radius;
    background-color: $foreground-color;
    border-color: $separator-color;

    .popover-body {
        color: $primary-color;
    }
}

.popover,
.tooltip {
    top: -145px !important;

    @include respond-below(xl) {
        top: -130px !important;
    }

    @include respond-below(lg) {
        top: -110px !important;
    }

    @include respond-below(sm) {
        top: -80px !important;
    }
}

.popover-header {
    background-color: transparent;
    border-bottom: initial;
}

.tooltip-inner {
    padding: 0.5rem 0.75rem;
    color: $primary-color;
    background-color: $foreground-color;
    border-radius: 0.1rem;
    border: 1px solid $separator-color;
}

.tooltip.show {
    opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
    border-right-color: $separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: $foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
    border-left-color: $separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: $foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: $separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: $foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
    border-top-color: $separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: $foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: $separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: $foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
    border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: $separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: $foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
    border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: $separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: $foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: $separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
    right: 0;
    border-left-color: $foreground-color;
}

.search-sm {
    position: relative;

    input {
        background: none;
        outline: initial !important;
        border-radius: 15px;
        padding: 0.25rem 0.75rem 0.25rem 0.75rem;
        font-size: 0.76rem;
        line-height: 1;
        border: 1px solid $secondary-color;
        color: $primary-color;
        height: initial;
    }

    &:after {
        font-family: "simple-line-icons";
        content: "\E090";
        font-size: 14px;
        color: $secondary-color;
        position: absolute;
        right: 10px;
        text-align: center;
        cursor: pointer;
        top: 2px;
    }
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0;
}

.calendar-sm::after {
    content: "\e075";
}

/* 27.Progress */
.progress {
    height: 3px;
    background-color: $separator-color-light;
}

.progress-bar {
    background-color: $theme-color-1;
}

.progressbar-text {
    position: absolute;
    left: 0;
    width: 50px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    color: $primary-color !important;
}

.progress-bar-circle {
    width: 54px;
    height: 54px;

    svg path:first-of-type {
        stroke: $separator-color;
    }

    svg path:last-of-type {
        stroke: $theme-color-1;
    }

    &.progress-bar-banner {
        svg path:first-of-type {
            stroke: mix($theme-color-1, $foreground-color, 70%);
        }

        svg path:last-of-type {
            stroke: $button-text-color;
        }
    }
}

.progress-banner {
    height: 200px;
    background-image: linear-gradient(to right top, $gradient-color-2, $gradient-color-3, $gradient-color-1);
    transition: 0.5s;
    background-size: 200% auto;
    cursor: pointer;

    .lead {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;

        @include respond-below(lg) {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
        }
    }

    i {
        font-size: 2.5rem;
        margin-bottom: 1rem;

        @include respond-below(lg) {
            font-size: 2rem;
            margin-bottom: 0.2rem;
        }
    }

    .progress-bar-circle.progress-bar-banner {
        width: 120px;
        height: 120px;

        @include respond-below(lg) {
            width: 80px;
            height: 80px;
        }
    }

    .progress-bar-banner {
        .progressbar-text {
            color: $button-text-color !important;
            font-size: 1.7rem;
            width: 110px;
            font-weight: 300;

            @include respond-below(lg) {
                font-size: 1.2rem;
                margin-bottom: 0.2rem;
            }
        }
    }

    &:hover {
        background-position: right top;
    }
}

/* 28.Rating */
.br-theme-bootstrap-stars .br-widget a:after {
    color: $separator-color;
}

.br-theme-bootstrap-stars .br-widget a.br-active:after {
    color: $theme-color-1;
    font-weight: 900;
}

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
    color: $theme-color-1;
    font-weight: 900;
}

/* 29.Sortable */
.sortable {
    cursor: default;
}

.sortable span {
    vertical-align: middle;
}

.sortable-ghost {
    opacity: 0.5;
}

/* 30.Spinner */
.btn-multiple-state {
    .spinner {
        width: 36px;
        text-align: center;
    }

    .spinner>div {
        width: 6px;
        height: 6px;
        background-color: $button-text-color;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
        animation: sk-bouncedelay 1.2s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0.3);
        opacity: 0;
    }

    40% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

/* 31.Croppper */
.cropper-line {
    background-color: $theme-color-1;
}

.cropper-point {
    background-color: $theme-color-1;
}

.cropper-view-box {
    outline-color: $theme-color-1;
    outline: $theme-color-1;
}

.cropper-preview {
    overflow: hidden;
}

#cropperContainer {
    height: 300px;
    display: none;
}

/* 32.Modal */
.modal {

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 1.75rem;
    }

    .modal-header {
        border-bottom: 1px solid $separator-color;
    }

    .modal-footer {
        border-top: 1px solid $separator-color;
    }

    .close {
        color: $primary-color;
        text-shadow: initial;
    }

    .modal-content {
        border: initial;
        border-radius: 0.1rem;
        background: $foreground-color;
    }
}

.modal-right {
    padding-right: 0 !important;

    .modal-dialog {
        margin: 0 auto;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        max-width: 380px;
    }

    .modal-content {
        min-height: 100%;
    }

    .modal-header {
        flex: 0 0 $navbar-height;

        @include respond-below(xl) {
            flex: 0 0 $navbar-height-lg;
        }

        @include respond-below(lg) {
            flex: 0 0 $navbar-height-md;
        }

        @include respond-below(sm) {
            flex: 0 0 $navbar-height-xs;
        }
    }

    .modal-footer {
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &.modal.fade .modal-dialog {
        transform: translate(25%, 0);
    }

    &.modal.show .modal-dialog {
        transform: translate(0, 0);
    }
}

.modal-left {
    padding-left: 0 !important;

    .modal-dialog {
        margin: 0 auto;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        max-width: 380px;
    }

    .modal-content {
        min-height: 100%;
    }

    .modal-header {
        flex: 0 0 $navbar-height;

        @include respond-below(xl) {
            flex: 0 0 $navbar-height-lg;
        }

        @include respond-below(lg) {
            flex: 0 0 $navbar-height-md;
        }

        @include respond-below(sm) {
            flex: 0 0 $navbar-height-xs;
        }
    }

    .modal-footer {
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &.modal.fade .modal-dialog {
        transform: translate(-25%, 0);
    }

    &.modal.show .modal-dialog {
        transform: translate(0, 0);
    }
}

/* 33.Authorization */
.auth-card {
    display: flex;
    flex-direction: row;
    @include depth(1);

    .image-side {
        width: 40%;
        background: url(../images/logos/stethoscope.jpg) no-repeat center top;
        background-size: cover;
        padding: 80px 40px;
        background-position: right;

        .h3 {
            line-height: 0.8rem;
        }
    }

    .form-side {
        width: 60%;
        padding: 80px;
    }

    @include respond-below(md) {
        flex-direction: column;

        .image-side {
            width: 100%;
            padding: 60px;
        }

        .form-side {
            width: 100%;
            padding: 60px;
        }
    }

    @include respond-below(sm) {
        p.h2 {
            font-size: 1.6rem;
        }
    }

    @include respond-below(xs) {
        flex-direction: column;

        .image-side {
            padding: 35px 30px;
        }

        .form-side {
            padding: 35px 30px;
        }

        .logo-single {
            margin-bottom: 20px;
        }

        p.h2 {
            font-size: 1.4rem;
        }
    }
}

.index-card .card-body {
    padding: 80px;

    @include respond-below(md) {
        padding: 60px;
    }

    @include respond-below(xs) {
        padding: 35px 30px;
    }
}

/* 34.Html Editors */
.html-editor {
    height: 350px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $theme-color-1;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: $theme-color-1;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
    stroke: $theme-color-1;
}

.ql-toolbar.ql-snow {
    border-color: $separator-color;
}

.ql-container.ql-snow {
    border-color: $separator-color;
}

.html-editor-bubble {
    height: 200px;
}

.html-editor-bubble .ql-editor {
    border: 1px solid $separator-color;
}

.ql-tooltip {
    z-index: 10;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: 350px;
    background: $foreground-color;
}

.ck.ck-button,
a.ck.ck-button {
    color: $separator-color;
}

.ck.ck-button:not(.ck-disabled):focus,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):focus,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button.ck-on:not(.ck-disabled):focus,
.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):focus,
a.ck.ck-button.ck-on:not(.ck-disabled):hover {
    background: initial;
    color: $theme-color-1;
    box-shadow: initial;
    outline: initial;
}

.ck.ck-toolbar {
    border-color: $separator-color !important;
}

.ck.ck-toolbar__separator {
    background: $separator-color !important;
}

.ck.ck-toolbar {
    border-color: $separator-color;
}

.ck.ck-editor__main>.ck-editor__editable {
    border-color: $separator-color !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background: $foreground-color;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
    box-shadow: initial !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
    border-color: $separator-color;
}

.ck.ck-toolbar {
    background: $foreground-color !important;
}

/* 35.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
    width: 14.28%;
    text-align: center !important;
    float: left;
    height: 100px;

    .glyph-icon,
    .fa {
        font-size: 32px;
        text-align: center !important;
    }

    .author-name {
        display: none;
    }

    .class-name {
        font-size: 0.76rem;
        color: $muted-color !important;
        text-align: center !important;
    }

    @include respond-below(lg) {
        width: 16.66%;
    }

    @include respond-below(md) {
        width: 20%;
    }

    @include respond-below(sm) {
        width: 25%;
    }

    @include respond-below(xs) {
        width: 50%;
    }
}

.chart-container {
    height: 300px;
}

.theme-colors {
    width: 280px !important;
    position: fixed;
    z-index: 1030;
    top: 50%;
    right: 0;
    background: $foreground-color;
    @include depth(1);
    transform: translate(280px, -50%);
    padding-top: 10px;
    padding-bottom: 10px;

    .theme-button {
        position: absolute;
        left: -32px;
        background: $foreground-color;
        padding: 13px 7px 13px 7px;
        border-radius: 0.2rem;
        color: $primary-color;
        box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $theme-color-1;
    }

    .theme-color {
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 20px;
        transition: background 0.25s;

        &.active,
        &:hover {
            background: $foreground-color;
        }
    }

    .theme-color-purplemonster {
        border: 3px solid $theme-color-purple-monster;
        background: $theme-color-purple-monster;
    }

    .theme-color-blueyale {
        border: 3px solid $theme-color-blue-yale;
        background: $theme-color-blue-yale;
    }

    .theme-color-blueolympic {
        border: 3px solid $theme-color-blue-olympic;
        background: $theme-color-blue-olympic;
    }

    .theme-color-bluenavy {
        border: 3px solid $theme-color-blue-navy;
        background: $theme-color-blue-navy;
    }

    .theme-color-orangecarrot {
        border: 3px solid $theme-color-orange-carrot;
        background: $theme-color-orange-carrot;
    }

    .theme-color-greenmoss {
        border: 3px solid $theme-color-green-moss;
        background: $theme-color-green-moss;
    }

    .theme-color-greenlime {
        border: 3px solid $theme-color-green-lime;
        background: $theme-color-green-lime;
    }

    .theme-color-redruby {
        border: 3px solid $theme-color-red-ruby;
        background: $theme-color-red-ruby;
    }

    .theme-color-greysteel {
        border: 3px solid $theme-color-grey-steel;
        background: $theme-color-grey-steel;
    }

    .theme-color-yellowgranola {
        border: 3px solid $theme-color-yellow-granola;
        background: $theme-color-yellow-granola;
    }

    &.shown {
        transform: translate(0, calc(-50% + 0.5px));
        transition: transform 0.4s ease-out;
    }

    &.hidden {
        transform: translate(280px, -50%);
        transition: transform 0.4s ease-out;
    }
}

/* 36.Loading */
.loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid rgba($theme-color-1, 0.2);
    border-radius: 50%;
    border-top-color: $theme-color-1;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    position: fixed;
    z-index: 1;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* 37.Media Library */
.library-app,
.select-from-library-container {
    .sfl-selected-item {
        display: none;
    }

    .list {
        margin-bottom: -1.5rem;
    }

    .drop-area-container {
        @include respond-below(lg) {
            margin-bottom: 1.5rem;
        }
    }

    .dropzone.dz-clickable .dz-message span {
        top: 0 !important;
    }

    .drop-area {
        min-height: 750px;
        height: 100%;

        .card-body {
            height: 100%;
        }

        form {
            height: 100%;
        }

        .dropzone {
            height: 100%;
        }

        @include respond-below(lg) {
            min-height: 220px;
            height: 100%;
        }

        .dropzone .dz-preview.dz-image-preview {
            width: calc(100% - 32px);

            @include respond-below(lg) {
                width: calc(50% - 32px);
            }

            @include respond-below(md) {
                width: calc(100% - 32px);
            }
        }
    }

    .preview-area {
        min-height: 750px;
        height: 100%;

        .card-body {
            height: 100%;
        }

        @include respond-below(lg) {
            min-height: 220px;
            height: auto;
            margin-bottom: 1.5rem;
        }

        @include respond-below(xs) {
            height: auto;
        }

        &.preview-area-audio {
            @include respond-below(lg) {
                height: auto;
            }
        }

        .card-img-top {
            @include respond-below(lg) {
                height: 220px;
                width: 220px;
                object-fit: cover;
            }

            @include respond-below(xs) {
                height: 180px;
                width: 100%;
                object-fit: cover;
            }
        }

        .video-view {
            height: 301px;

            @include respond-below(xs) {
                height: 180px;
                width: 100%;
            }

            .video-js {
                width: 100%;
                height: 100%;
                background-color: $background-color;

                .vjs-poster {
                    background-size: cover;
                }
            }
        }

        .audio-view {
            height: 60px;

            .video-js.audio {
                width: 100%;
                height: 100%;
            }
        }
    }

    .media-thumb-container {
        height: 105px;
        padding: 20px 30px;

        &.active-default {
            @include depth(2);
        }

        .card-body {
            padding-left: 1rem;
        }

        @include respond-below(xs) {
            padding: 1.5rem;
        }
    }

    .list-media-thumbnail {
        border-radius: $border-radius;
        padding: 0;
        border: initial;
        height: auto;
        max-width: unset;
        width: 55px;
        height: 45px;
        object-fit: cover;
    }

    .media-thumbnail-icon {
        width: 60px;
        height: 45px;

        i {
            font-size: 35px;
            margin: 0 auto;
            line-height: 48px;
            color: $theme-color-1;
        }
    }

    .dropzone.disabled {
        opacity: 0.4;

        .dropzone-message {
            position: relative;
            transform: translateY(-50%);
            top: 50%;
            display: block;
            text-align: center;
        }
    }
}

.sfl-item-container {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.select-from-library-container {
    margin-bottom: -2rem;
}

.select-from-library-button,
.selected-library-item {
    border: 1px solid $separator-color;
    height: 110px;

    .card {
        box-shadow: initial;
    }
}

.select-from-library-button {
    cursor: pointer;
    border-style: dashed;
}

.delete-library-item {
    font-size: 18px;
}

.select-from-library {
    .modal-content {
        background: $background-color !important;
        height: 100%;

        .ps__thumb-y {
            right: 14px;
        }
    }

    .list {
        margin: 0;

        .row {
            margin-left: -0.5rem;
            margin-right: -0.5rem;
        }
    }

    .media-thumb-container.card {
        height: 175px;
    }

    img.list-media-thumbnail {
        width: 100%;
        height: 105px;
        object-fit: cover;
    }

    .modal-body.scroll {
        margin-right: initial;
    }

    .dropzone-container {
        flex: 0 0 auto;
    }

    .folder-button-collapse {
        display: inline-block;
        border-radius: initial;
        width: 100%;
        text-align: left;
        margin-bottom: 1rem;

        .icon-container {
            margin-right: 0.5rem;
            display: inline-block;

            i {
                display: inline-block;
                font-size: 14px;
                margin: 0 auto;
                transform: rotate(0deg);
                transition: transform 0.3s;
            }
        }

        .folder-name {
            line-height: 22px;
            display: inline-block;
            font-size: 1rem;
        }

        &.collapsed {
            .icon-container {
                i {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .dropzone {
        min-height: 160px;
        height: 100%;
        border: initial;
    }

    .dz-default.dz-message {
        top: 75px;
    }

    .dropzone .dz-preview.dz-image-preview {
        width: calc(100% - 32px);
    }
}

/* 38.Context Menu */
.context-menu-list {
    box-shadow: initial;
    border-radius: $border-radius;
    background: $input-background;
    border-color: rgba($primary-color, 0.15);
    padding: 0.5rem 0;
}

.context-menu-item {
    padding: 0.75rem 1.5rem;
    background: $input-background;
    color: $primary-color;

    &.context-menu-hover {
        color: $primary-color;
        text-decoration: none;
        background-color: $background-color;
    }
}

.context-menu-item span {
    font-family: "Nunito", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 0.5rem;
    display: inline-block;
}

.list .card.context-menu-active,
.list .card.active {
    @include depth(2);
}

/* 39.Videojs */
.video-js .vjs-big-play-button {
    background: $input-background;
    height: 1.2em;
    border-radius: 0.75em;
    line-height: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.5em;
    width: 2.5em;
    border: 0.06666em solid $input-background;

    .vjs-icon-placeholder {
        color: $theme-color-1;
    }
}

.vjs-poster,
.vjs-tech {
    border-radius: $border-radius;
}

.vjs-tech {
    background: initial;
    object-fit: cover;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
    background-color: darken($input-background, 5%);
    border-color: darken($input-background, 5%);
}

.vjs-control {
    text-shadow: initial !important;
    outline: initial !important;
}

.video-js .vjs-control-bar {
    background: initial;
    margin: 1.75rem;
    width: calc(100% - 3.5rem);

    .vjs-control.vjs-button,
    .vjs-remaining-time,
    .vjs-volume-panel {
        margin-right: 0.5em;
        background: $input-background;
        color: $theme-color-1;
        border-radius: 15px;
        @include depth(2);
    }

    .vjs-progress-control.vjs-control {
        background: $input-background;
        border-radius: 15px;
        margin-right: 0.5em;
        @include depth(2);
    }

    .vjs-mute-control.vjs-control {
        box-shadow: initial;
    }

    .vjs-progress-holder {
        height: 3px;
        font-size: 1.6em !important;
    }

    .vjs-load-progress,
    .vjs-load-progress div {
        background-color: rgba($theme-color-1, 0.2);
    }

    .vjs-play-progress:before {
        font-size: 0.55em;
        top: -0.2em;
    }

    .vjs-progress-holder {
        margin: 0 17px;
    }

    .vjs-slider {
        text-shadow: initial !important;
        outline: initial !important;
        background-color: darken($separator-color-light, 10%);
    }

    .vjs-play-progress {
        background: $theme-color-1;
    }

    .vjs-play-progress:before {
        color: $theme-color-1;
    }

    .vjs-volume-horizontal {
        margin-left: -1.5em;
        width: 4em;
    }

    .vjs-volume-panel .vjs-volume-level {
        background: $theme-color-1;
    }
}

.video-js.audio {
    background: initial;

    .vjs-big-play-button {
        display: none;
    }

    .vjs-control-bar {
        display: flex;
    }

    .vjs-fullscreen-control {
        display: none;
    }

    .vjs-control-bar {
        margin-bottom: 0;
    }

    .vjs-control.vjs-button,
    .vjs-remaining-time,
    .vjs-volume-panel {
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
    }

    .vjs-progress-control.vjs-control {
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
    }

    .vjs-mute-control {
        box-shadow: initial !important;
    }

    .vjs-loading-spinner {
        display: none !important;
    }
}

.video-js.side-bar-video {
    width: 100%;
    height: 280px;
    background-color: $background-color;
    overflow: hidden;

    video {
        object-fit: cover;
    }

    .vjs-poster {
        background-size: cover;
    }
}

.video-js.video-content {
    width: 100%;
    height: 400px;
    background-color: $background-color;
    overflow: hidden;
    max-height: unset;

    video {
        object-fit: cover;
    }

    .vjs-poster {
        background-size: cover;
    }
}

/* 40.Prices */
.equal-height-container {
    .col-item {
        .card {
            height: 100%;
        }

        .card-body {
            @include respond-below(md) {
                text-align: left;
            }

            @include respond-below(xs) {
                text-align: center;
            }
        }

        .price-top-part {
            text-align: center;

            * {
                text-align: center !important;
            }

            @include respond-below(md) {
                padding-left: 0;
                padding-right: 0;
                width: 40%;
            }

            @include respond-below(xs) {
                width: initial;
            }
        }

        .price-feature-list {
            justify-content: space-between;

            ul {
                margin: 0 auto;
                align-self: flex-start;
                margin-bottom: 1rem;

                li p {
                    text-align: center !important;
                }
            }
        }
    }
}

.table-heading {
    box-shadow: initial;
    background: initial;
}

i.large-icon {
    font-size: 38px;
    line-height: 90px;
    color: $theme-color-1;

    @include respond-below(sm) {
        line-height: 70px;
    }
}

.large-icon.initial-height {
    line-height: initial;

    @include respond-below(sm) {
        line-height: initial;
    }
}

/* 41.Tables */

.table {
    color: $primary-color;
}

.table th,
.table td {
    border-color: $separator-color-light !important;
}

.table .thead-light th {
    background-color: $separator-color-light !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: $separator-color-light !important;
}

.feature-row {
    margin-top: 80px;

    @include respond-below(sm) {
        margin-top: 40px;
    }
}

/* 42.Invoice Print */
@media print {
    body {
        background: white;
        height: 100%;
    }

    main {
        margin: 0 !important;
    }

    .navbar,
    .menu,
    .theme-colors {
        display: none;
    }

    footer {
        display: none;
    }

    .invoice {
        width: 100%;
        max-width: 100%;

        &.row {
            margin: 0;
        }

        [class*="col"] {
            padding: 0;
        }

        .invoice-contents {
            width: 100%;
            max-width: 100% !important;
            height: 1370px !important;
        }
    }
}

/* 43.Profile Page */
.social-header {
    width: 100%;
    height: 380px;
    object-fit: cover;
    max-height: 380px;
}

.social-profile-img {
    height: 120px;
    border: 3px solid $foreground-color;
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    top: -75px;
}

.social-icons {
    ul {
        margin-bottom: 0;
    }

    li {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        a {
            color: $secondary-color;
            font-size: 1.2rem;

            &:hover,
            &:active {
                color: rgba($secondary-color, 0.7);
            }
        }
    }
}

.post-icon {
    i {
        font-size: 1.1rem;
        vertical-align: middle;
    }

    span {
        display: inline-block;
        padding-top: 3px;
        vertical-align: middle;
    }
}

.comment-likes {
    text-align: right;
    flex-basis: 90px;
    flex-shrink: 0;
}

.comment-contaiener {
    input {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        padding-left: 20px;
    }
}

.social-image-row {
    [class*="col-"] {
        margin-bottom: 1rem;
    }

    [class*="col-"]:nth-last-child(-n + 2) {
        margin-bottom: 0;
    }

    [class*="col-"]:nth-child(odd) {
        padding-right: 0.5rem;
    }

    [class*="col-"]:nth-child(even) {
        padding-left: 0.5rem;
    }
}

.gallery-page.row {
    margin-left: -5px;
    margin-right: -5px;

    [class*="col-"] {
        margin-bottom: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.col-left {
    padding-right: 20px;

    @include respond-below(md) {
        padding-right: 15px;
    }
}

.col-right {
    padding-left: 20px;

    @include respond-below(md) {
        padding-left: 15px;
    }
}

/* 44.Glide */

.glide__slides {
    white-space: initial;

    .glide__slide {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.glide__slide {
    display: flex;
    height: unset;

    .card {
        display: flex;
        flex-grow: 1;

        .w-50 {
            display: flex;
        }

        .card-body {
            flex-direction: column;
            display: flex;
            justify-content: space-between;
        }
    }
}

.slider-nav {
    text-align: center;

    .left-arrow,
    .right-arrow {
        padding-top: 7px;
        font-size: 20px;
        color: $theme-color-1;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        border: initial;
        position: initial;
        transform: initial;
        top: initial;
        left: initial;
        right: initial;
        padding-left: 5px;
        padding-right: 5px;
    }

    .glide__arrow {
        padding-top: 10px;
    }

    .slider-dot-container {
        display: inline-block;
        position: initial;
        text-align: center;
        transform: initial;
        left: initial;
    }

    .btn {

        &:hover,
        &:focus,
        &:active {
            text-decoration: initial;
        }
    }
}

.slider-dot {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background: $separator-color;
    outline: initial !important;
    border: initial;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    box-shadow: initial;

    &.glide__bullet:hover,
    &.glide__bullet:focus {
        background: $separator-color;
        border: initial;
    }

    &.glide__bullet--active {
        background: $theme-color-1 !important;
    }
}

.glide {
    .card-img {
        width: 100%;
        height: auto;
    }
}

.glide.details {
    img {
        margin-bottom: 0;
        object-fit: cover;
    }

    .glide__slides {
        margin-bottom: 0;
    }

    .glide__slide {
        padding: initial;
    }
}

.glide.thumbs {
    cursor: pointer;
    width: 340px;
    margin: 0 auto;

    @include respond-below(sm) {
        width: 300px;
    }

    @include respond-below(xs) {
        width: calc(100% - 80px);
    }

    .glide__slide {
        padding: initial;
    }

    li {
        text-align: center;
        opacity: 0.4;
        transition: opacity $animation-time-short;
        height: 45px;
        object-fit: cover;

        &.active,
        &:hover {
            opacity: 1;
        }

        img {
            width: 60px;
            margin-bottom: 0;
        }
    }

    .glide__slides {
        margin-bottom: 0;
    }

    .glide__arrows {
        width: 100%;

        .glide__arrow--left {
            border: initial;
            background: initial;
            box-shadow: initial;
            top: 20px;
            left: -50px;
            font-size: 20px;
            color: $theme-color-1;
        }

        .glide__arrow--right {
            border: initial;
            background: initial;
            box-shadow: initial;
            top: 20px;
            right: -50px;
            font-size: 20px;
            color: $theme-color-1;
        }
    }
}

.glide.details {
    img {
        width: 100%;

        @include respond-below(md) {
            max-height: 500px;
        }

        @include respond-below(sm) {
            max-height: 400px;
        }

        @include respond-below(xs) {
            max-height: 300px;
        }

        @include respond-below(xxs) {
            max-height: 200px;
        }
    }
}

/* 46.Blog */
.listing-card-container {
    .listing-heading {
        height: 45px;
        overflow: hidden;
    }

    .listing-desc {
        height: 60px;
        overflow: hidden;
        line-height: 1.4;

        @include respond-below(lg) {
            height: 60px;
        }
    }
}

/* 47.Rounded */
.rounded {
    .alert-primary.animated {
        border-radius: $border-radius-rounded;
    }

    .popover,
    .tooltip-inner {
        border-radius: $border-radius-rounded;
    }

    .border-radius {
        border-radius: $border-radius-rounded;
    }

    .vjs-poster,
    .vjs-tech {
        border-radius: $border-radius-rounded;
    }

    .dashboard-top-rated .glide img {
        border-radius: $border-radius-rounded;
    }

    .app-menu {
        border-top-left-radius: $border-radius-rounded;
        border-bottom-left-radius: $border-radius-rounded;
    }

    .sub-menu {
        border-radius: 0 $border-radius-rounded $border-radius-rounded 0;
    }

    .auth-card .image-side {
        border-top-left-radius: $border-radius-rounded;
        border-bottom-left-radius: $border-radius-rounded;

        @include respond-below(md) {
            border-bottom-right-radius: initial;
            border-bottom-left-radius: initial;
            border-top-left-radius: $border-radius-rounded;
            border-top-right-radius: $border-radius-rounded;
        }

        @include respond-below(sm) {}

        @include respond-below(xs) {}
    }

    .card-img,
    .card-img-fluid,
    .card-img-overlay {
        border-radius: $border-radius-rounded;
    }

    &.sub-hidden .main-menu,
    &.menu-sub-hidden .main-menu {
        border-radius: 0 $border-radius-rounded $border-radius-rounded 0;
    }

    &.sub-show-temporary .main-menu {
        border-radius: initial;
    }

    .dropdown-menu {
        border-radius: $border-radius-rounded;
    }

    .btn.default {
        border-radius: $border-radius;
    }

    .list-thumbnail {
        border-radius: $border-radius-rounded;
    }

    .card-img-bottom,
    .card-img-bottom .vjs-poster,
    .card-img-bottom .vjs-tech {
        border-bottom-left-radius: $border-radius-rounded;
        border-bottom-right-radius: $border-radius-rounded;
        border-top-left-radius: initial;
        border-top-right-radius: initial;
    }

    .card-img-top,
    .card-img-top .vjs-poster,
    .card-img-top .vjs-tech {
        border-top-left-radius: $border-radius-rounded;
        border-top-right-radius: $border-radius-rounded;
        border-bottom-left-radius: initial;
        border-bottom-right-radius: initial;
    }

    .card-img-left {
        border-top-left-radius: $border-radius-rounded;
        border-bottom-left-radius: $border-radius-rounded;
        border-top-right-radius: initial !important;
        border-bottom-right-radius: initial !important;
    }

    .card-img-right {
        border-top-right-radius: $border-radius-rounded;
        border-bottom-right-radius: $border-radius-rounded;
        border-top-left-radius: initial !important;
        border-bottom-left-radius: initial !important;
    }

    .card {
        border-radius: $border-radius-rounded;
    }

    .card.auth-card {
        border-top-left-radius: $border-radius-rounded + 0.5rem;
        border-bottom-left-radius: $border-radius-rounded + 0.5rem;

        @include respond-below(md) {
            border-top-left-radius: $border-radius-rounded + 0.5rem;
            border-top-right-radius: $border-radius-rounded + 0.5rem;
            border-bottom-right-radius: $border-radius-rounded;
            border-bottom-left-radius: $border-radius-rounded;
        }
    }

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
        border-radius: 10px;
    }

    .data-table-rows {
        table {
            tbody tr {
                border-radius: $border-radius-rounded;
            }

            td {
                background: $foreground-color;

                &:first-child {
                    border-top-left-radius: $border-radius-rounded;
                    border-bottom-left-radius: $border-radius-rounded;
                }

                &:last-child {
                    border-top-right-radius: $border-radius-rounded;
                    border-bottom-right-radius: $border-radius-rounded;
                }
            }
        }
    }
}

/* 48.Rtl */
.rtl {
    //Basic
    direction: rtl;

    .text-center p,
    .text-center div,
    .text-center span,
    .text-center i,
    .text-center a,
    .text-center h1,
    .text-center h2,
    .text-center h3,
    .text-center h4,
    .text-center h5,
    .text-center h6 {
        text-align: center;
    }

    .list-unstyled {
        padding-right: 0;
    }

    .breadcrumb-item+.breadcrumb-item {
        padding-left: initial;
        padding-right: 0.5rem;

        &::before {
            padding-left: 0.5rem;
            padding-right: initial;
        }
    }

    .glide__arrow.glide__arrow--left {
        .simple-icon-arrow-left:before {
            content: "\e605";
        }
    }

    .glide__arrow.glide__arrow--right {
        .simple-icon-arrow-right:before {
            content: "\e606";
        }
    }

    .glide__arrows {
        direction: ltr;
    }

    .glide__bullets {
        direction: rtl;
    }

    .dropdown-menu:not(.datepicker-dropdown) {
        right: initial !important;
    }

    .dropdown-menu-right {
        right: initial !important;
        left: 0 !important;
    }

    .dropdown-menu-left {
        right: 0 !important;
        left: initial !important;
    }

    .btn-group>.btn:first-child {
        margin-left: -1px;
    }

    .top-right-button-container {
        float: left;

        @include respond-below(xs) {
            float: initial;
        }
    }

    .col-left {
        padding-left: 20px;
        padding-right: 15px;

        @include respond-below(md) {
            padding-left: 15px;
        }
    }

    .col-right {
        padding-right: 20px;
        padding-left: 15px;

        @include respond-below(md) {
            padding-right: 15px;
        }
    }

    .search-sm::after {
        left: 7px;
        right: initial;
        text-align: initial;
        top: 3px;
    }

    .newsletter-input-container {
        input {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            border-top-right-radius: 50px !important;
            border-bottom-right-radius: 50px !important;
        }
    }

    .custom-control-label::after,
    .custom-control-label::before {
        left: initial;
        right: -1.5rem;
    }

    .context-menu-item span {
        margin-left: initial;
        margin-right: 0.5rem;
    }

    .typeahead.dropdown-menu {
        right: 0 !important;
    }

    .typeahead-container .btn {
        border-radius: 0.1rem !important;
    }

    .typeahead-container .input-group-append {
        margin-left: 0;
        margin-right: -1px;
    }

    .dropdown-toggle-split:after,
    .dropright .dropdown-toggle-split:after,
    .dropup .dropdown-toggle-split:after {
        margin-left: 2px;
        margin-right: 2px;
    }

    .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
    .input-group>.input-group-append:not(:last-child)>.btn,
    .input-group>.input-group-append:not(:last-child)>.input-group-text,
    .input-group>.input-group-prepend>.btn,
    .input-group>.input-group-prepend>.input-group-text {
        border-radius: $border-radius;
    }

    .btn-group.dropleft {
        .dropdown-toggle-split {
            border-radius: $border-radius;
        }
    }

    .fc-toolbar.fc-header-toolbar .btn-group .fc-next-button.btn,
    .fc-toolbar.fc-header-toolbar .btn-group .fc-prev-button.btn {
        border-radius: 40px !important;
    }

    .input-group-append .btn+.btn,
    .input-group-append .btn+.input-group-text,
    .input-group-append .input-group-text+.btn,
    .input-group-append .input-group-text+.input-group-text,
    .input-group-prepend .btn+.btn,
    .input-group-prepend .btn+.input-group-text,
    .input-group-prepend .input-group-text+.btn,
    .input-group-prepend .input-group-text+.input-group-text {
        margin-left: 0px;
        margin-right: -1px;
    }

    .input-group>.input-group-append>.btn,
    .input-group>.input-group-append>.input-group-text,
    .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
    .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
    .input-group>.input-group-prepend:not(:first-child)>.btn,
    .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
        border-radius: $border-radius;
    }

    .input-group-text {
        border-radius: $border-radius !important;
    }

    .input-group-prepend {
        margin-right: 0;
        margin-left: -1px;
    }

    .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
    .input-group>.input-group-append:not(:last-child)>.btn,
    .input-group>.input-group-append:not(:last-child)>.input-group-text,
    .input-group>.input-group-prepend>.btn,
    .input-group>.input-group-prepend>.input-group-text {
        border-radius: 50px;
    }

    .input-group>.input-group-append>.btn,
    .input-group>.input-group-append>.input-group-text,
    .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
    .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
    .input-group>.input-group-prepend:not(:first-child)>.btn,
    .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
        border-radius: 50px;
    }

    .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
    .input-group>.input-group-append:not(:last-child)>.btn,
    .input-group>.input-group-append:not(:last-child)>.input-group-text,
    .input-group>.input-group-prepend>.btn,
    .input-group>.input-group-prepend>.input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
        border-radius: 0 !important;
    }

    .input-group>.input-group-append>.btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .sw-main.sw-theme-check>ul.step-anchor>li>a:after,
    .sw-main.sw-theme-dots>ul.step-anchor>li>a:after {
        left: 0;
        right: 50%;
        transform: translateX(50%);
    }

    .tooltip-label-right .invalid-tooltip::before,
    .tooltip-label-right .valid-tooltip::before {
        left: initial;
        right: -5px;
        border-right: initial;
        border-left: solid 5px $theme-color-1;
    }

    .tooltip-label-right .invalid-tooltip::after,
    .tooltip-label-right .valid-tooltip::after {
        right: -4px;
        left: initial;
        border-left: solid 5px $foreground-color;
        border-right: initial;
    }

    .tooltip-label-right .invalid-tooltip,
    .tooltip-label-right .valid-tooltip {
        left: initial;
        right: 50px;
    }

    .tooltip-label-right .invalid-tooltip:after,
    .tooltip-label-right .valid-tooltip:after,
    .tooltip-label-right div.error:after {
        left: auto;
        right: -9px;
        border-right: 5px solid transparent;
        border-left: 5px solid $foreground-color;
    }

    .tooltip-label-right .invalid-tooltip:before,
    .tooltip-label-right .valid-tooltip:before,
    .tooltip-label-right div.error:before {
        left: auto;
        right: -10px;
        border-right: 5px solid transparent;
        border-left: 5px solid $theme-color-1;
    }

    .tooltip-label-right .invalid-tooltip,
    .tooltip-label-right .valid-tooltip,
    .tooltip-label-right div.error {
        left: initial;
        right: 50px;
    }

    .tooltip-label-right {
        .error-l-0 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                right: 0px;
                left: initial;
            }
        }

        .error-l-25 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 25px;
            }
        }

        .error-l-50 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 50px;
            }
        }

        .error-l-75 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 75px;
            }
        }

        .error-l-100 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 100px;
            }
        }

        .error-l-125 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 125px;
            }
        }

        .error-l-150 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 150px;
            }
        }

        .error-l-175 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 175px;
            }
        }

        .error-l-200 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 200px;
            }
        }

        .error-l-225 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 225px;
            }
        }

        .error-l-250 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 250px;
            }
        }

        .error-l-275 {

            .invalid-tooltip,
            .valid-tooltip,
            div.error {
                left: initial;
                right: 275px;
            }
        }
    }

    .invalid-icon,
    .valid-icon {
        right: initial;
        left: 4px;
    }

    table.dataTable thead>tr>th.sorting_asc,
    table.dataTable thead>tr>th.sorting_desc,
    table.dataTable thead>tr>th.sorting,
    table.dataTable thead>tr>td.sorting_asc,
    table.dataTable thead>tr>td.sorting_desc,
    table.dataTable thead>tr>td.sorting {
        padding-right: 0;
        padding-left: 30px;
    }

    table.dataTable thead .sorting:before,
    table.dataTable thead .sorting_asc:before,
    table.dataTable thead .sorting_asc_disabled:before,
    table.dataTable thead .sorting_desc:before,
    table.dataTable thead .sorting_desc_disabled:before {
        right: initial;
        left: 2em;
    }

    table.dataTable thead .sorting:after,
    table.dataTable thead .sorting_asc:after,
    table.dataTable thead .sorting_asc_disabled:after,
    table.dataTable thead .sorting_desc:after,
    table.dataTable thead .sorting_desc_disabled:after {
        right: initial;
        left: 1.5em;
    }

    .ck-editor {
        direction: rtl;
    }

    .btn-group>.btn:not(:first-child),
    .btn-group>.btn-group:not(:first-child)>.btn {
        border-radius: initial;
    }

    .btn-group>.btn:first-child {
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .btn-group>.btn:last-of-type {
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        right: initial;
        left: 0;
    }

    .datepicker-dropdown {
        right: initial;
    }

    .card .card-header .card-icon {
        left: 10px;
        right: initial;
    }

    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
        margin-right: inherit;
        margin-left: initial;
    }

    .pagination {
        .page-link.prev {
            i::before {
                content: "\e606";
            }
        }

        .page-link.next {
            i::before {
                content: "\e605";
            }
        }

        .page-link.first {
            i::before {
                content: "\e074";
            }
        }

        .page-link.last {
            i::before {
                content: "\e06f";
            }
        }
    }

    .nav,
    .pagination {
        padding-right: 0;
    }

    .nav-tabs.separator-tabs .nav-link {
        margin-left: 1.5rem;
        margin-right: 0;
    }

    .heading-number {
        margin-right: 0;
        margin-left: 10px;
    }

    .card-top-buttons {
        right: initial;
        left: 0;
    }

    .comment-likes {
        text-align: left;
    }

    .comment-contaiener input {
        border-top-left-radius: initial;
        border-bottom-left-radius: initial;
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        padding-left: inherit;
        padding-right: 20px;
    }

    .social-image-row {
        [class*="col-"]:nth-child(odd) {
            padding-left: 0.5rem;
            padding-right: 15px;
        }

        [class*="col-"]:nth-child(even) {
            padding-left: 15px;
            padding-right: 0.5rem;
        }
    }

    .app-row {
        padding-right: initial;
        padding-left: $app-menu-width;

        @include respond-below(lg) {
            padding-left: 0;
        }
    }

    .app-menu {
        left: 0;
        right: initial;

        @include respond-below(lg) {
            transform: translateX(-$app-menu-width);
        }

        &.shown {
            transform: translateX(0);
        }

        .app-menu-button {
            left: calc(#{$app-menu-width} - 2px);
            box-shadow: 4px 0 5px rgba(0, 0, 0, 0.04);
        }
    }

    .fc-toolbar.fc-header-toolbar .fc-prev-button {
        margin-right: 0;
    }

    .fc-toolbar.fc-header-toolbar .fc-next-button {
        margin-right: 5px;
    }

    .float-right {
        float: left !important;
    }

    .float-left {
        float: right !important;
    }

    @include respond-above(xl) {
        .float-xxl-left {
            float: right !important;
        }

        .float-xxl-right {
            float: left !important;
        }
    }

    @include respond-above(lg) {
        .float-xl-left {
            float: right !important;
        }

        .float-xl-right {
            float: left !important;
        }
    }

    @include respond-above(md) {
        .float-lg-left {
            float: right !important;
        }

        .float-lg-right {
            float: left !important;
        }
    }

    @include respond-above(sm) {
        .float-md-left {
            float: right !important;
        }

        .float-md-right {
            float: left !important;
        }
    }

    @include respond-above(xs) {
        .float-sm-left {
            float: right !important;
        }

        .float-sm-right {
            float: left !important;
        }
    }

    @include respond-above(xxs) {
        .float-xs-left {
            float: right !important;
        }

        .float-xs-right {
            float: left !important;
        }
    }

    .badge {
        &.badge-top-left {
            left: initial;
            right: -7px;
        }

        &.badge-top-left-2 {
            left: initial;
            right: -7px;
        }

        &.badge-top-right {
            left: -7px;
            right: initial;
        }

        &.badge-top-right-2 {
            left: -7px;
            right: initial;
        }
    }

    .r-0 {
        left: 0;
        right: initial;
    }

    .form-check {
        padding-left: 0;
        padding-right: 1.25rem;
    }

    .has-float-label label,
    .has-float-label>span:last-of-type {
        right: 0.75rem;
        left: initial;
    }

    .has-top-label label,
    .has-top-label>span:last-of-type {
        right: 0.75rem;
        left: initial;
    }

    .select2-selection__arrow {
        right: initial;
        left: 12px;
    }

    .nav-pills .nav-link {
        text-align: center;
    }

    .custom-switch .custom-switch-input+.custom-switch-btn:after {
        left: 0;
    }

    .custom-switch .custom-switch-input:checked+.custom-switch-btn:after {
        left: -28px;
    }

    .custom-switch-small .custom-switch-input:checked+.custom-switch-btn:after {
        left: -18px;
    }

    .bootstrap-tagsinput .tag {
        padding-right: 10px;
        padding-left: 20px;

        span {
            margin-left: 0px;
            position: relative;

            &:after {
                position: absolute;
                top: -2px;
                left: -15px;
            }
        }
    }

    .ps__rail-y {
        right: initial !important;
        left: 0 !important;
    }

    .scroll {
        margin-left: -15px;
        padding-left: 15px;
        margin-right: 0;
        padding-right: 0;
    }

    .modal {

        .modal-header,
        .modal-body,
        .modal-footer {
            padding: 1.75rem;

            @include respond-below(xs) {
                padding: 1.5rem;
            }
        }
    }

    .select-from-library {
        .modal-body.scroll {
            margin-left: 0;

            .ps__rail-y {
                left: 10px !important;
            }
        }

        .sfl-item-container {
            .card-body {
                padding-right: 1.75rem !important;

                @include respond-below(xs) {
                    padding: 1.5rem !important;
                }
            }

            .custom-control {
                padding-left: 0.25rem !important;
            }
        }
    }

    .menu .sub-menu .scroll .ps__thumb-y,
    .menu .main-menu .scroll .ps__thumb-y {
        right: -4px;
    }

    .dropzone .dz-preview.dz-image-preview .dz-progress {
        right: 50%;
        left: initial;
        transform: translateX(50%);
    }

    .dropzone .dz-preview.dz-file-preview .remove,
    .dropzone .dz-preview.dz-image-preview .remove {
        right: initial;
        left: 5px;
    }

    .chart {
        direction: ltr;

        canvas {
            direction: rtl;
        }
    }

    .noUi-horizontal .noUi-origin {
        left: initial !important;
        right: initial !important;
    }

    .icon-button {
        text-align: center;
    }

    // Sidebar
    .menu {
        right: 0;
        left: initial;

        .main-menu {
            left: initial;
            right: 0;

            ul li {
                position: relative;

                &.active:after {
                    content: " ";
                    left: initial;
                    right: 0;
                }
            }
        }

        .sub-menu {
            left: initial;
            right: $main-menu-width;
            border-right: 1px solid $separator-color-light;
            @include depth(2);

            ul {
                li {
                    margin-left: initial;
                    margin-right: 30px;

                    i {
                        margin-right: initial;
                        margin-left: 10px;
                    }

                    &.active:after {
                        left: initial;
                        right: -16px;
                    }
                }
            }

            ul.inner-level-menu {
                li {
                    &.active:after {
                        left: initial;
                        right: -22px;
                    }
                }
            }

            @include respond-below(xl) {
                left: initial;
                right: $main-menu-width-lg;
            }

            @include respond-below(lg) {
                left: initial;
                right: $main-menu-width-md;
            }

            @include respond-below(sm) {
                left: initial;
                right: $main-menu-width-xs;
            }
        }
    }

    // Main
    main {
        margin-right: $sub-menu-width + $main-menu-width + $main-margin;
        margin-left: $main-margin !important;
        overflow: initial !important;

        &.default-transition {
            transition: margin-right $menu-collapse-time;
        }

        @include respond-below(xl) {
            margin-right: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
            margin-left: $main-margin-lg !important;
        }

        @include respond-below(lg) {
            margin-right: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
            margin-left: $main-margin-md !important;
        }

        @include respond-below(sm) {
            margin-right: $main-margin-xs !important;
            margin-left: $main-margin-xs !important;
        }

        @include respond-below(xs) {
            margin-bottom: 0;
        }
    }

    // Navbar
    .navbar {
        .navbar-right {
            text-align: left;
        }

        .menu-button-mobile {
            margin-right: 15px;
            margin-left: initial;
        }

        .menu-button {
            text-align: center;
        }

        .icon-menu-item {
            text-align: center;

            i {
                text-align: center;
            }

            span {
                text-align: center;
            }
        }

        .search {
            .search-icon {
                right: initial;
                left: 5px;
            }

            @include respond-below(sm) {
                margin-right: 0.6rem;
                margin-left: initial;

                input {
                    display: none;
                }

                &.mobile-view {
                    margin-left: initial;
                    margin-right: 15px;

                    input {
                        display: block;
                        width: 100%;
                        height: 70px;
                        padding-left: 0;
                    }

                    span {
                        left: 15px;
                        right: initial;
                    }
                }
            }
        }

        .navbar-right {
            text-align: left;

            .user {
                margin-left: $main-margin;
                margin-right: 0;

                @include respond-below(xl) {
                    margin-left: $main-margin-lg;
                    margin-right: 0;
                }

                @include respond-below(lg) {
                    margin-left: $main-margin-md;
                    margin-right: 0;
                }

                @include respond-below(sm) {
                    margin-left: $main-margin-xs;
                    margin-right: 0;
                }

                img {
                    margin-right: 10px;
                    margin-left: initial;
                }
            }

            .header-icons {
                margin-right: initial;
                margin-left: 0.5rem;

                @include respond-below(xs) {
                    margin-left: 0;
                }
            }

            #notificationButton {
                .count {
                    text-align: center;

                    @include respond-below(xs) {
                        left: -1px;
                    }
                }
            }

            #userDropdown,
            #userDropdown * {
                text-align: left;
            }
        }
    }

    .heading-icon {
        margin-right: initial;
        margin-left: 5px;
    }

    .survey-app .answers input {
        padding-right: 0.75rem;
        padding-left: 70px;
    }

    .input-icons {
        left: 0;
        right: initial;
    }

    .simple-icon-arrow-left:before {
        content: "\e606";
    }

    .simple-icon-arrow-right:before {
        content: "\e605";
    }

    //Menu Config Main Menu Sub Menu
    &#app-container {

        &.sub-hidden .sub-menu,
        &.menu-sub-hidden .sub-menu,
        &.menu-hidden .sub-menu {
            transform: translateX($sub-menu-width);

            @include respond-below(xl) {
                transform: translateX($sub-menu-width-lg);
            }

            @include respond-below(lg) {
                transform: translateX($sub-menu-width-md);
            }

            @include respond-below(sm) {
                transform: translateX($sub-menu-width-xs);
            }
        }

        &.main-hidden .main-menu,
        &.menu-hidden .main-menu {
            transform: translateX($main-menu-width);

            @include respond-below(xl) {
                transform: translateX($main-menu-width-lg);
            }

            @include respond-below(lg) {
                transform: translateX($main-menu-width-md);
            }

            @include respond-below(sm) {
                transform: translateX($main-menu-width-xs);
            }
        }

        &.main-hidden.sub-hidden .sub-menu,
        &.menu-hidden .sub-menu {
            transform: translateX($sub-menu-width + $main-menu-width);

            @include respond-below(xl) {
                transform: translateX($sub-menu-width-lg + $main-menu-width-lg);
            }

            @include respond-below(lg) {
                transform: translateX($sub-menu-width-md + $main-menu-width-md);
            }

            @include respond-below(sm) {
                transform: translateX($sub-menu-width-xs + $main-menu-width-xs);
            }
        }

        &.menu-main-hidden {
            .main-menu {
                width: 0;
            }

            .sub-menu {
                right: 0;
            }
        }

        //Mobile
        &.menu-mobile {
            .main-menu {
                transform: translateX($main-menu-width-xs);
            }

            .sub-menu {
                transform: translateX($sub-menu-width-xs + $main-menu-width-xs + 50);
            }
        }

        //Show Temporary
        &.main-show-temporary {
            .main-menu {
                transform: translateX(0);
            }

            .sub-menu {
                transform: translateX($sub-menu-width);
            }

            @include respond-below(xl) {
                .main-menu {
                    transform: translateX(0);
                }

                .sub-menu {
                    transform: translateX($sub-menu-width-lg);
                }
            }

            @include respond-below(lg) {
                .main-menu {
                    transform: translateX(0);
                }

                .sub-menu {
                    transform: translateX($sub-menu-width-md);
                }
            }

            @include respond-below(sm) {
                .sub-menu {
                    transform: translateX($sub-menu-width-xs);
                }
            }
        }

        &.sub-show-temporary,
        &.menu-mobile.sub-show-temporary,
        &.menu-main-hidden.menu-mobile.main-show-temporary {
            .sub-menu {
                transform: translateX(0);
            }
        }
    }

    // Chat Input Container
    .chat-input-container {
        padding-right: $main-menu-width + $main-margin + $sub-menu-width;
        padding-left: $app-menu-width + $main-margin;

        @include respond-below(xl) {
            padding-right: $main-menu-width-lg + $main-margin-lg + $sub-menu-width-lg;
            padding-left: $app-menu-width + $main-margin-lg;
        }

        @include respond-below(lg) {
            padding-right: $main-menu-width-md + $main-margin-md + $sub-menu-width-md;
            padding-left: $main-margin-md;
        }

        @include respond-below(sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &.menu-sub-hidden,
    &.sub-hidden {
        .chat-input-container {
            padding-right: $main-menu-width + $main-margin;

            @include respond-below(xl) {
                padding-right: $main-menu-width-lg + $main-margin-lg;
            }

            @include respond-below(lg) {
                padding-right: $main-menu-width-md + $main-margin-md;
            }

            @include respond-below(sm) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    &.menu-main-hidden,
    &.menu-hidden,
    &.main-hidden {
        .chat-input-container {
            padding-right: $main-margin;

            @include respond-below(xl) {
                padding-right: $main-margin-lg;
            }

            @include respond-below(lg) {
                padding-right: $main-margin-md;
            }

            @include respond-below(sm) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    // Menu Config Main
    &#app-container {

        &.sub-hidden main,
        &.menu-sub-hidden main,
        &.menu-hidden main {
            margin-right: $main-menu-width + $main-margin;
        }

        &.main-hidden main,
        &.menu-hidden main {
            margin-right: $main-margin;
        }

        &.menu-main-hidden {
            main {
                margin-right: $main-margin + $sub-menu-width;
            }

            &.menu-hidden {
                main {
                    margin-right: $main-margin;
                }
            }
        }

        @include respond-below(xl) {

            &.sub-hidden main,
            &.menu-sub-hidden main,
            &.menu-hidden main {
                margin-right: $main-menu-width-lg + $main-margin-lg;
            }

            &.main-hidden main,
            &.menu-hidden main {
                margin-right: $main-margin-lg;
            }

            &.menu-main-hidden {
                main {
                    margin-right: $main-margin-lg + $sub-menu-width-lg;
                }

                &.menu-hidden {
                    main {
                        margin-right: $main-margin-lg;
                    }
                }
            }
        }

        @include respond-below(lg) {

            &.sub-hidden main,
            &.menu-sub-hidden main,
            &.menu-hidden main {
                margin-right: $main-menu-width-md + $main-margin-md;
            }

            &.main-hidden main,
            &.menu-hidden main {
                margin-right: $main-margin-md;
            }

            &.menu-main-hidden {
                main {
                    margin-right: $main-margin-md + $sub-menu-width-md;
                }

                &.menu-hidden {
                    main {
                        margin-right: $main-margin-md;
                    }
                }
            }
        }
    }

    .theme-colors {
        transform: translate(-280px, -50%);
        left: 0;
        right: initial;

        .theme-button {
            left: 278px;
            box-shadow: 3px 0 5px rgba(0, 0, 0, 0.04);
        }

        &.shown {
            transform: translate(0, -50%);
        }
    }

    // Rounded
    &.rounded {
        .app-menu {
            border-top-right-radius: $border-radius-rounded;
            border-bottom-right-radius: $border-radius-rounded;
        }

        .sub-menu {
            border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
        }

        .auth-card .image-side {
            border-top-left-radius: $border-radius-rounded;
            border-bottom-left-radius: $border-radius-rounded;
        }

        .card-img,
        .card-img-fluid,
        .card-img-overlay {
            border-radius: $border-radius-rounded;
        }

        &.sub-hidden .main-menu,
        &.menu-sub-hidden .main-menu {
            border-radius: $border-radius-rounded 0 0 $border-radius-rounded;
        }

        &.sub-show-temporary .main-menu {
            border-radius: initial;
        }

        .card-img-left {
            border-top-right-radius: $border-radius-rounded !important;
            border-bottom-right-radius: $border-radius-rounded !important;
            border-top-left-radius: initial !important;
            border-bottom-left-radius: initial !important;
        }

        .card-img-right {
            border-top-left-radius: $border-radius-rounded !important;
            border-bottom-left-radius: $border-radius-rounded !important;
            border-top-right-radius: initial !important;
            border-bottom-right-radius: initial !important;
        }

        .card.auth-card {
            border-top-left-radius: $border-radius-rounded;
            border-bottom-left-radius: $border-radius-rounded;
            border-top-right-radius: $border-radius-rounded + 0.5rem;
            border-bottom-right-radius: $border-radius-rounded + 0.5rem;
        }

        .auth-card .image-side {
            border-top-left-radius: initial;
            border-bottom-left-radius: initial;
            border-top-right-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;
        }

        .data-table-rows {
            table {
                td {
                    border-radius: initial;

                    &:first-child {
                        border-top-right-radius: $border-radius-rounded;
                        border-bottom-right-radius: $border-radius-rounded;
                    }

                    &:last-child {
                        border-top-left-radius: $border-radius-rounded;
                        border-bottom-left-radius: $border-radius-rounded;
                    }
                }
            }

            .data-table-rows-check {
                text-align: left;
                padding-left: initial;
            }
        }
    }
}

//Footer

body {
    min-height: calc(100% - 150px);
    position: relative;
    padding-bottom: 90px;

    @include respond-below(xl) {
        min-height: calc(100% - 130px);
    }

    @include respond-below(lg) {
        min-height: calc(100% - 110px);
    }

    @include respond-below(sm) {
        min-height: calc(100% - 85px);
    }

    @include respond-below(xs) {
        padding-bottom: 60px;
    }

    &.no-footer {
        padding-bottom: initial;
    }
}

footer.page-footer {
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    border-top: 1px solid $separator-color;
    width: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;

    @include respond-below(xs) {
        height: 60px;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        color: $theme-color-1;
    }

    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .footer-content {
        margin-left: $sub-menu-width + $main-menu-width + $main-margin;
        margin-right: $main-margin;

        @include respond-below(xl) {
            margin-left: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
            margin-right: $main-margin-lg;
        }

        @include respond-below(lg) {
            margin-left: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
            margin-right: $main-margin-md;
        }

        @include respond-below(sm) {
            margin-left: $main-margin-xs !important;
            margin-right: $main-margin-xs !important;
        }
    }
}

.right-menu {
    footer.page-footer {
        padding-right: $app-menu-width;

        @include respond-below(lg) {
            padding-right: 0;
        }
    }
}

#app-container {

    &.sub-hidden .footer-content,
    &.menu-sub-hidden .footer-content,
    &.menu-hidden .footer-content {
        margin-left: $main-menu-width + $main-margin;
    }

    &.main-hidden .footer-content,
    &.menu-hidden .footer-content {
        margin-left: $main-margin;
    }

    &.menu-main-hidden {
        .footer-content {
            margin-left: $main-margin + $sub-menu-width;
        }

        &.menu-hidden {
            .footer-content {
                margin-left: $main-margin;
            }
        }
    }

    @include respond-below(xl) {

        &.sub-hidden .footer-content,
        &.menu-sub-hidden .footer-content,
        &.menu-hidden .footer-content {
            margin-left: $main-menu-width-lg + $main-margin-lg;
        }

        &.main-hidden .footer-content,
        &.menu-hidden .footer-content {
            margin-left: $main-margin-lg;
        }

        &.menu-main-hidden {
            .footer-content {
                margin-left: $main-margin-lg + $sub-menu-width-lg;
            }

            &.menu-hidden {
                .footer-content {
                    margin-left: $main-margin-lg;
                }
            }
        }
    }

    @include respond-below(lg) {

        &.sub-hidden .footer-content,
        &.menu-sub-hidden .footer-content,
        &.menu-hidden .footer-content {
            margin-left: $main-menu-width-md + $main-margin-md;
        }

        &.main-hidden .footer-content,
        &.menu-hidden .footer-content {
            margin-left: $main-margin-md;
        }

        &.menu-main-hidden {
            .footer-content {
                margin-left: $main-margin-md + $sub-menu-width-md;
            }

            &.menu-hidden {
                .footer-content {
                    margin-left: $main-margin-md;
                }
            }
        }
    }
}

.right-menu-always-hidden {
    .app-menu {
        transform: translateX($app-menu-width);

        &.shown {
            transform: translateX(0);
        }
    }

    .app-row,
    .page-footer {
        padding-right: 0;
    }

    footer.page-footer {
        padding-right: initial;
    }

    .chat-input-container {
        padding-right: $main-margin;

        @include respond-below(xl) {
            padding-left: $main-menu-width-lg + $main-margin-lg;
            padding-right: $main-margin-lg;
        }

        @include respond-below(lg) {
            padding-left: $main-menu-width-md + $main-margin-md;
            padding-right: $main-margin-md;
        }

        @include respond-below(sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

// Footer RTL
.rtl {
    footer.page-footer {
        .footer-content {
            margin-right: $sub-menu-width + $main-menu-width + $main-margin;
            margin-left: $main-margin;

            @include respond-below(xl) {
                margin-right: $sub-menu-width-lg + $main-menu-width-lg + $main-margin-lg;
                margin-left: $main-margin-lg;
            }

            @include respond-below(lg) {
                margin-right: $sub-menu-width-md + $main-menu-width-md + $main-margin-md;
                margin-left: $main-margin-md;
            }

            @include respond-below(sm) {
                margin-right: $main-margin-xs !important;
                margin-left: $main-margin-xs !important;
            }
        }
    }

    &#app-container {

        &.sub-hidden .footer-content,
        &.menu-sub-hidden .footer-content,
        &.menu-hidden .footer-content {
            margin-right: $main-menu-width + $main-margin;
            margin-left: $main-margin;
        }

        &.main-hidden .footer-content,
        &.menu-hidden .footer-content {
            margin-right: $main-margin;
            margin-left: $main-margin;
        }

        &.menu-main-hidden {
            .footer-content {
                margin-right: $main-margin + $sub-menu-width;
                margin-left: $main-margin;
            }

            &.menu-hidden {
                .footer-content {
                    margin-right: $main-margin;
                    margin-left: $main-margin;
                }
            }
        }

        @include respond-below(xl) {

            &.sub-hidden .footer-content,
            &.menu-sub-hidden .footer-content,
            &.menu-hidden .footer-content {
                margin-right: $main-menu-width-lg + $main-margin-lg;
                margin-left: $main-margin-lg;
            }

            &.main-hidden .footer-content,
            &.menu-hidden .footer-content {
                margin-right: $main-margin-lg;
                margin-left: $main-margin-lg;
            }

            &.menu-main-hidden {
                .footer-content {
                    margin-right: $main-margin-lg + $sub-menu-width-lg;
                    margin-left: $main-margin-lg;
                }

                &.menu-hidden {
                    .footer-content {
                        margin-right: $main-margin-lg;
                        margin-left: $main-margin-lg;
                    }
                }
            }
        }

        @include respond-below(lg) {

            &.sub-hidden .footer-content,
            &.menu-sub-hidden .footer-content,
            &.menu-hidden .footer-content {
                margin-right: $main-menu-width-md + $main-margin-md;
                margin-left: $main-margin-md;
            }

            &.main-hidden .footer-content,
            &.menu-hidden .footer-content {
                margin-right: $main-margin-md;
                margin-left: $main-margin-md;
            }

            &.menu-main-hidden {
                .footer-content {
                    margin-right: $main-margin-md + $sub-menu-width-md;
                    margin-left: $main-margin-md;
                }

                &.menu-hidden {
                    .footer-content {
                        margin-right: $main-margin-md;
                        margin-left: $main-margin-md;
                    }
                }
            }
        }
    }

    &.right-menu {
        footer.page-footer {
            padding-left: $app-menu-width;
            padding-right: initial;

            @include respond-below(lg) {
                padding-left: 0;
            }
        }
    }
}

/* 49.Landingpage */
.landing-page {
    font-size: 1rem;
    line-height: 1.6rem;
    color: $secondary-color;

    @include respond-below(sm) {
        font-size: 0.9rem;
        line-height: 1.4rem;
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        transform: translateX(300px);
        z-index: 25;
        width: 300px;
        background: $background-color;
        box-shadow: initial;
        transition: transform 0.5s, box-shadow 0.5s;

        // &.default-transition {
        //   transition: transform 0.5s, box-shadow 0.5s;
        // }
    }

    .logo-mobile {
        display: block;
        text-align: center;
        padding: 3rem 2rem;
        background: none;
        width: unset;
        height: unset;

        span {
            display: inline-block;
            width: 100px;
            height: 30px;
            background: url($lp-logo-path-pinned);
            background-repeat: no-repeat;
        }
    }

    .main-container {
        overflow: hidden;
    }

    &.show-mobile-menu {
        .mobile-menu {
            transform: translateX(0);
            @include depth(2);
        }

        .main-container {

            .content-container,
            nav {
                transform: translateX(-300px);
            }
        }
    }

    .content-container {
        transition: transform 0.5s;
    }

    p {
        font-size: 1rem;
        line-height: 1.6rem;
        color: $secondary-color;

        @include respond-below(sm) {
            font-size: 0.9rem;
            line-height: 1.4rem;
        }
    }

    h1 {
        font-size: 3rem;
        color: $theme-color-1;
        margin-bottom: 2rem;

        @include respond-below(sm) {
            font-size: 2rem;
        }
    }

    h2 {
        font-size: 1.8rem;
        color: $theme-color-1;
        margin-bottom: 2rem;

        @include respond-below(md) {
            font-size: 1.6rem;
        }

        @include respond-below(sm) {
            font-size: 1.2rem;
        }
    }

    h3 {
        font-size: 1.4rem;
        line-height: 1.4;
        color: $theme-color-1;

        @include respond-below(sm) {
            font-size: 1.1rem;
        }
    }

    .semi-rounded {
        border-radius: 1rem !important;
    }

    .dropdown-toggle::after {
        opacity: 0.7;
    }

    .btn-outline-semi-light {
        border: 1px solid rgba($button-text-color, 0.3);
        color: $button-text-color;
        background: transparent;

        &:hover,
        &:active {
            border: 1px solid darken($theme-color-1, 10%);
            background: darken($theme-color-1, 10%);
        }
    }

    .landing-page-nav {
        z-index: 5 !important;
        top: 0;
        position: fixed;
        width: 100%;
        background: transparent;
        box-shadow: initial;

        nav {
            transition: transform 0.5s;
        }

        &.headroom {
            transition: top 0.5s, background 0.5s, box-shadow 0.2s, transform 0.5s !important;
        }

        .container {
            height: $navbar-height;

            @include respond-below(xl) {
                height: $navbar-height-lg;
            }

            @include respond-below(lg) {
                height: $navbar-height-md;

                .navbar-logo {
                    width: 100px;
                    height: 30px;

                    .white,
                    .dark {
                        width: 100px;
                        height: 30px;
                    }
                }
            }

            @include respond-below(sm) {
                height: $navbar-height-xs;
            }
        }

        .btn {
            padding-right: 1.75rem !important;
            padding-left: 1.75rem !important;
        }

        .navbar-logo {
            width: 120px;
            height: 40px;

            .white {
                display: inline-block;
                width: 120px;
                height: 40px;
                background: url($lp-logo-path);
                background-repeat: no-repeat;
            }

            .dark {
                width: 120px;
                height: 40px;
                background: url($lp-logo-path-pinned);
                background-repeat: no-repeat;
                display: none;
            }
        }

        .mobile-menu-button {
            font-size: 20px;
            display: none;
            cursor: pointer;

            @include respond-below(md) {
                display: block;
            }
        }

        .navbar-nav {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;

            li {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                position: relative;
                margin-bottom: initial;

                &:last-of-type {
                    padding-right: 0;
                }

                &.active::before {
                    content: " ";
                    background: $button-text-color;
                    border-radius: 10px;
                    position: absolute;
                    width: calc(100% - 1.5rem);
                    height: 4px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 0;
                }

                @include respond-below(lg) {
                    padding-left: 0.85rem;
                    padding-right: 0.85rem;

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            .nav-item>a,
            .nav-item>.dropdown>a {
                color: $button-text-color;
                font-family: Nunito, sans-serif;
                font-size: 1rem;
                display: inline-block;
                margin-top: 0;
            }

            .nav-item:not(.active)>a,
            .nav-item:not(.active) .dropdown>a {

                &:hover:not(.btn),
                &:active:not(.btn) {
                    opacity: 0.8;
                }
            }

            .dropdown {
                height: 80px;
                position: relative;

                &:hover>.dropdown-menu {
                    display: block;
                }

                &>.dropdown-toggle:active {
                    pointer-events: none;
                }

                .dropdown-menu {
                    position: absolute;
                    margin-top: 0;
                }

                a.dropdown-item {
                    font-size: 0.8rem;
                    color: $primary-color;
                    padding-bottom: 0.5rem;

                    &.active,
                    &:active {
                        color: $button-text-color;
                    }
                }
            }
        }
    }

    .landing-page-nav {
        &.headroom--pinned {
            background: $foreground-color;
            @include depth(1);

            .navbar-logo .white {
                display: none;
            }

            .navbar-logo .dark {
                display: inline-block;
            }
        }

        &.headroom--not-top {
            .navbar-nav {
                a {
                    color: $primary-color;

                    &:hover,
                    &:active {
                        color: $theme-color-1;
                    }
                }

                a.dropdown-item {
                    &:focus {
                        color: $button-text-color;
                    }
                }

                li {
                    &.active::before {
                        content: " ";
                        background-color: $theme-color-1;
                    }
                }

                .btn-outline-semi-light {
                    border: 1px solid rgba($theme-color-1, 0.3);
                    color: $theme-color-1;

                    &:hover,
                    &:active {
                        border: 1px solid $theme-color-1;
                        background: $theme-color-1;
                        color: $button-text-color;
                    }
                }
            }
        }

        &.headroom--unpinned {
            display: none;
        }

        &.headroom--top {
            box-shadow: none;
            background: transparent;

            .navbar-logo .white {
                display: inline-block;
            }

            .navbar-logo .dark {
                display: none;
            }
        }

        &.headroom--unfixed {
            .mobile-menu-button {
                color: $button-text-color;
            }
        }
    }

    .section {
        margin-bottom: 150px;
        padding-top: 100px;
        padding-bottom: 100px;

        @include respond-below(md) {
            margin-bottom: 100px;
            padding-top: 60px;
        }

        &.home {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1440'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='862.49' y1='-420.67' x2='1546.24' y2='981.21' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='" + encodecolor($lp-bg-color-1 ) + "'/%3E%3Cstop offset='0.27' stop-color='" + encodecolor($lp-bg-color-2) + "'/%3E%3Cstop offset='0.63' stop-color='" + encodecolor($lp-bg-color-3 ) + "'/%3E%3Cstop offset='0.88' stop-color='" + encodecolor($lp-bg-color-4) + "'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-home%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M0,0V952.71l497.08,428.77c44.64,38.51,122.25,50,172.46,25.57L2600,467.26V0Z'/%3E%3Cpath style='fill:" + encodecolor($lp-bg-color-4 ) + ";opacity:0.3;' d='M624.43,1418.11c-44.33,4.25-94.67-9.44-127.35-37.63L0,952.48v50.82l452.08,389.18C496.69,1431,574.21,1442.48,624.43,1418.11Z'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position-y: 0;
            background-color: transparent;
            height: 1440px;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            position: relative;
            background-size: cover;

            @include respond-below(xs) {
                height: 1340px;
            }
        }

        &.subpage {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1040'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='929.23' y1='-453.21' x2='1676' y2='1077.89' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='" + encodecolor($lp-bg-color-1 ) + "'/%3E%3Cstop offset='0.27' stop-color='" + encodecolor($lp-bg-color-2) + "'/%3E%3Cstop offset='0.63' stop-color='" + encodecolor($lp-bg-color-3 ) + "'/%3E%3Cstop offset='0.88' stop-color='" + encodecolor($lp-bg-color-4) + "'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-sub%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M170.66,739.24,522.89,996.51c31.63,23.1,89.75,35.48,129.15,27.5L2166.74,717.1,2600,627.22V0H0V614Z'/%3E%3Cpath style='fill:" + encodecolor($lp-bg-color-4 ) + ";opacity:0.3;' d='M442.89,993.51c31.63,23.1,89.75,35.48,129.15,27.5l4.62-.94c-20.5-5.19-39.74-13.32-53.77-23.56L170.66,739.24,0,614v55.73l90.66,66.55Z'/%3E%3C/g%3E%3C/svg%3E");
            background-position-y: -370px;
            background-repeat: no-repeat;
            background-size: auto 1040px;
            height: 670px;

            @include respond-below(lg) {
                background-position-y: -450px;
                height: 590px;
            }

            @include respond-below(md) {
                background-position-y: -470px;
                height: 570px;
            }

            @include respond-below(sm) {
                background-position-y: -530px;
                height: 510px;
            }

            .btn-circle.hero-circle-button {
                bottom: initial;
            }
        }

        &.subpage-long {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1040'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='929.23' y1='-453.21' x2='1676' y2='1077.89' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='" + encodecolor($lp-bg-color-1 ) + "'/%3E%3Cstop offset='0.27' stop-color='" + encodecolor($lp-bg-color-2) + "'/%3E%3Cstop offset='0.63' stop-color='" + encodecolor($lp-bg-color-3 ) + "'/%3E%3Cstop offset='0.88' stop-color='" + encodecolor($lp-bg-color-4) + "'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3Ebackground-sub%3C/title%3E%3Cg%3E%3Cpath style='fill:url(%23linear-gradient);' d='M170.66,739.24,522.89,996.51c31.63,23.1,89.75,35.48,129.15,27.5L2166.74,717.1,2600,627.22V0H0V614Z'/%3E%3Cpath style='fill:" + encodecolor($lp-bg-color-4 ) + ";opacity:0.3;' d='M442.89,993.51c31.63,23.1,89.75,35.48,129.15,27.5l4.62-.94c-20.5-5.19-39.74-13.32-53.77-23.56L170.66,739.24,0,614v55.73l90.66,66.55Z'/%3E%3C/g%3E%3C/svg%3E");
            height: 1040px;
            background-repeat: no-repeat;
            background-position-y: -200px;
        }

        &.footer {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 2600 1100'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='-153' y1='642.88' x2='3129.11' y2='642.88' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.1' stop-color='" + encodecolor($lp-bg-color-1 ) + "'/%3E%3Cstop offset='0.27' stop-color='" + encodecolor($lp-bg-color-2) + "'/%3E%3Cstop offset='0.63' stop-color='" + encodecolor($lp-bg-color-3 ) + "'/%3E%3Cstop offset='0.88' stop-color='" + encodecolor($lp-bg-color-4) + "'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' y1='264.94' x2='3129.11' y2='264.94' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Cg%3E%3Cpath style='fill:" + encodecolor($lp-bg-color-4 ) + ";opacity:0.3;' d='M2019.51,188.57c17.85,4.08,35.64,13,52.49,29.43l528,635.74V787.56L2127,218C2092.59,184.43,2054.24,182.23,2019.51,188.57Z'/%3E%3Cpath style='fill:url(%23linear-gradient);' d='M1945,193,0,714v386H2600V853.33L2072,218C2031,178,1984.41,182.53,1945,193Z'/%3E%3Cpath style='fill:url(%23linear-gradient-2);' d='M2308.31,91c-60.24,17.09-77.23,69.94-37.76,117.43l210.94,253.81c26.74,32.19,72.83,54,118.51,59.37V8.25Z'/%3E%3C/g%3E%3C/svg%3E");
            background-color: $foreground-color;
            background-repeat: no-repeat;
            padding-bottom: 0;
            background-size: cover;
            background-size: auto 1100px;
            padding-top: 340px;
            background-position-y: 150px;
            margin-top: -150px;

            @include respond-below(md) {
                padding-top: 380px;
            }

            @include respond-below(xs) {
                padding-top: 380px;
            }
        }
    }

    .section.background {
        background: $foreground-color;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;

        @include respond-below(md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &::before {
            content: " ";
            width: 100%;
            height: 70px;
            position: absolute;
            top: -70px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 70'%3E%3Cg%3E%3Cpath style='fill:" + encodecolor($foreground-color ) + "' d='M2600,70v-.71C2254.44,26.19,1799.27,0,1300.5,0,801.25,0,345.68,26.24,0,69.42V70Z'/%3E%3C/g%3E%3C/svg%3E");
            background-size: cover;
            background-position: center;
        }

        &::after {
            content: " ";
            width: 100%;
            height: 70px;
            position: absolute;
            bottom: -70px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2600 70'%3E%3Cg%3E%3Cpath style='fill:" + encodecolor($foreground-color ) + "' d='M0,0V.71C345.56,43.81,800.73,70,1299.5,70,1798.75,70,2254.32,43.76,2600,.58V0Z'/%3E%3C/g%3E%3C/svg%3E");
            background-size: cover;
            background-position: center;
        }

        &.background-no-bottom {
            &::after {
                content: "";
                background: initial;
                width: initial;
                height: initial;
            }
        }
    }

    .background-white {
        background: $button-text-color;
    }

    .mobile-hero {
        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 135%;
        margin-bottom: 3rem;
    }

    .home-row {
        padding-top: 180px;
        margin-bottom: 70px;

        @include respond-below(xl) {
            padding-top: 120px;
        }

        @include respond-below(md) {
            padding-top: 110px;
        }
    }

    .home-text {
        margin-top: 40px;

        p {
            color: $button-text-color;
            font-size: 1.1rem;

            @include respond-below(sm) {
                font-size: 0.9rem;
                line-height: 1.4rem;
            }
        }

        .display-1 {
            font-size: 3rem;
            line-height: 3.2rem;
            margin-bottom: 1.1em;
            color: $button-text-color;

            @include respond-below(md) {
                font-size: 1.8rem;
                line-height: 2.5rem;
            }

            @include respond-below(sm) {
                font-size: 1.7rem;
                line-height: 2.3rem;
            }

            @include respond-below(xs) {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }

        @include respond-below(sm) {
            margin-top: 0;
        }
    }

    .review-carousel {
        margin-top: 5rem;

        .owl-stage-outer {
            padding-top: 5px;
        }
    }

    .home-carousel {
        cursor: grab;

        ::-moz-selection {
            /* Code for Firefox */
            color: inherit;
            background: inherit;
        }

        ::selection {
            color: inherit;
            background: inherit;
        }

        .card {
            .detail-text {
                padding: 1rem 0.25rem;
                margin-bottom: 0;

                @include respond-below(sm) {
                    padding: 0.25rem;
                }
            }

            .btn-link {
                @include respond-below(sm) {
                    padding: 0.25rem;
                }
            }
        }
    }

    i.large-icon {
        font-size: 60px;
        line-height: 110px;
        color: $theme-color-1;
        display: initial;

        @include respond-below(sm) {
            line-height: 90px;
        }
    }

    .btn-circle {
        padding: 0;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        text-align: center;
        font-size: 14px;
    }

    .btn-circle.hero-circle-button {
        position: absolute;
        bottom: 80px;
        margin-left: 10px;

        i {
            padding-top: 18px;
            display: inline-block;
        }
    }

    .newsletter-input-container {
        margin-top: 5rem;

        .input-group {
            border-radius: 50px;
            @include depth(2);
        }

        input {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            border-color: transparent !important;
            padding: 0.75rem 1.75rem 0.75rem;
            font-size: 0.9rem;
        }

        button {
            @include respond-below(sm) {
                padding: 1rem 1.5rem 0.9rem;
            }
        }
    }

    .footer {
        .footer-content {
            padding-top: 210px;

            @include respond-below(sm) {
                padding-top: 140px;
            }

            @include respond-below(xs) {
                padding-top: 80px;
            }
        }

        .footer-circle-button {
            margin-top: 80px;
            margin-right: 20px;

            @include respond-below(md) {
                margin-top: 110px;
            }

            i {
                padding-top: 15px;
                display: inline-block;
            }
        }

        .footer-logo {
            width: 140px;
            margin-bottom: 70px;
        }

        .footer-menu {
            p {
                color: $button-text-color;
                font-size: 1rem;
            }

            a {
                font-size: 1rem;
                color: $button-text-color;

                &:hover,
                &:focus {
                    color: darken($theme-color-1, 20%);
                }
            }

            .collapse-button {
                i {
                    font-size: 0.75rem;
                    margin-left: 5px;
                }

                &:hover,
                &:focus {
                    color: $button-text-color;
                }
            }
        }

        .separator {
            opacity: 0.3;
        }

        .copyright {
            p {
                color: $button-text-color;
            }
        }

        .social-icons {
            ul {
                margin-bottom: 0;
            }

            li {
                margin-left: 0.5rem;
                margin-right: 0.5rem;

                a {
                    color: $button-text-color;
                    font-size: 1.2rem;

                    &:hover,
                    &:active {
                        color: rgba($button-text-color, 0.7);
                    }
                }
            }
        }
    }

    .feature-image-left {
        float: right;
    }

    .feature-image-left,
    .feature-image-right {
        @include respond-below(sm) {
            margin-left: 50%;
            transform: translateX(-50%);
            max-width: 115%;
            float: initial;
        }
    }

    .heading-team {
        @include respond-below(sm) {
            text-align: center;
        }
    }

    .mobile-menu {
        text-align: center;

        img {
            width: 100px;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        ul {
            text-align: left;

            li {
                padding: 0.5rem 2.5rem;
                margin-bottom: unset;

                a {
                    font-size: 0.9rem;
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                position: static !important;
                background: initial;
                border: initial;
                padding: initial;
                margin: initial;
                transform: initial !important;
                margin-top: 0.5rem;
            }

            a.dropdown-item {
                color: $primary-color;
                padding-bottom: 0.5rem;

                &.active,
                &:active {
                    color: $button-text-color;
                }
            }
        }
    }

    .side-bar {
        @include respond-below(md) {
            margin-top: 5rem;
        }

        .side-bar-content {
            margin-bottom: 5rem;
        }

        h2 {
            @include respond-below(sm) {
                margin-bottom: 1rem;
            }
        }
    }

    .listing-card-container {
        background: initial;
        box-shadow: initial;

        .listing-heading {
            height: 65px;
            overflow: hidden;
        }

        .listing-desc {
            height: 68px;
            overflow: hidden;
            line-height: 1.4;
            font-size: 1rem;

            @include respond-below(lg) {
                height: 46px;
            }
        }
    }

    .video-heading {
        height: 38px;
        overflow: hidden;
    }

    .table-heading {
        box-shadow: initial;
        background: initial;
    }

    form.dark-background {
        input:-webkit-autofill {
            color: white !important;
            -webkit-text-fill-color: white !important;
        }

        .has-float-label>span,
        .has-float-label label,
        .has-top-label>span,
        .has-top-label label {
            color: rgba($button-text-color, 0.6);
        }

        .bootstrap-tagsinput,
        .form-control {
            background: transparent;
            border-color: rgba($button-text-color, 0.3);
            color: $button-text-color;

            &:focus,
            &:active {
                border-color: rgba($button-text-color, 0.6);
            }
        }
    }

    .has-float-label>span,
    .has-float-label label,
    .has-top-label>span,
    .has-top-label label {
        font-size: 64%;
    }

    .video-js.blog-video {
        width: 100%;
        height: 442px;
        background-color: $background-color;

        @include respond-below(md) {
            height: 280px;
        }

        .vjs-poster {
            background-size: cover;
        }
    }

    .video-js.side-bar-video {
        width: 100%;
        height: 300px;
        background-color: $background-color;

        .vjs-poster {
            background-size: cover;
        }
    }

    .feature-icon-container {
        .detail-text {
            min-height: 100px;

            @include respond-below(md) {
                min-height: 70px;
            }
        }
    }

    .screenshots {
        .nav-tabs {
            margin-bottom: 0 !important;
            border: initial;

            .nav-link {
                border: initial;
                background: initial !important;
                padding-right: 40px;
                padding-left: 40px;
            }

            .nav-item.show .nav-link:before,
            .nav-link.active:before {
                top: initial;
                bottom: 0;
            }
        }
    }

    .app-image {
        width: 100%;
        @include depth(2);
        border-radius: 1.2rem;
    }

    .doc-search {
        border: 1px solid rgba($button-text-color, 0.3);
        background: transparent;
        border-radius: 40px;
        padding: 0.85rem 0.75rem 0.8rem;
        max-width: 360px;

        input {
            color: $button-text-color;
            background: transparent;
            width: 93%;
            padding: 0 0.75rem;
            outline: initial !important;
            border: initial;

            &::placeholder {
                color: $button-text-color;
                opacity: 0.7;
            }
        }

        i {
            font-size: 16px;
            color: rgba($button-text-color, 0.7);
        }
    }

    .feedback-container {
        a {
            font-size: 1.3em;
            color: $secondary-color;
            margin: 0.5rem;

            &:hover,
            &:active {
                color: $theme-color-1;
            }
        }
    }

    .video-play-icon {
        width: 100%;
        height: 100%;
        position: absolute;

        span {
            position: absolute;
            font-family: VideoJS;
            font-weight: 400;
            font-style: normal;
            background: rgba(255, 255, 255, 0.7);
            height: 1.25em;
            border-radius: 0.75em;
            line-height: 1.25em;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.75em;
            width: 2em;
            text-align: center;

            &:before {
                color: $theme-color-1;
                content: "\f101";
            }
        }

        &:hover,
        &:active {
            span {
                background: rgba(255, 255, 255, 0.85);
            }
        }
    }

    .page-item .page-link {
        line-height: 1.2;
    }
}

.mobile-menu-button,
.headroom--top .mobile-menu-button,
.headroom--pinned.headroom--top .mobile-menu-button {
    color: $button-text-color;
}

.headroom--pinned .mobile-menu-button {
    color: $primary-color;
}

.components-image {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @include respond-below(md) {
        width: 1600px;
    }

    @include respond-below(sm) {
        width: 1200px;
    }
}

.color-left {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 33.33%;
    height: 20px;
}

.color-center {
    width: 33.33%;
    height: 20px;
}

.color-right {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    width: 33.33%;
    height: 20px;
}

.color-container {
    height: 20px;
    display: flex;
    justify-content: stretch;
    border-radius: 1rem;
    background-color: initial;
}

.bluenavy-light-1 {
    background-color: #00365a;
}

.bluenavy-light-2 {
    background-color: #fff;
}

.bluenavy-light-3 {
    background-color: #184f90;
}

.bluenavy-dark-1 {
    background-color: #236591;
}

.bluenavy-dark-2 {
    background-color: #1e2022;
}

.bluenavy-dark-3 {
    background-color: #1d477a;
}

.blueolympic-light-1 {
    background-color: #008ecc;
}

.blueolympic-light-2 {
    background-color: #fff;
}

.blueolympic-light-3 {
    background-color: #73c2fb;
}

.blueolympic-dark-1 {
    background-color: #3e83a1;
}

.blueolympic-dark-2 {
    background-color: #1e2022;
}

.blueolympic-dark-3 {
    background-color: #304d72;
}

.blueyale-light-1 {
    background-color: #145388;
}

.blueyale-light-2 {
    background-color: #fff;
}

.blueyale-light-3 {
    background-color: #2a93d5;
}

.blueyale-dark-1 {
    background-color: #38688b;
}

.blueyale-dark-2 {
    background-color: #1e2022;
}

.blueyale-dark-3 {
    background-color: #3e8ab9;
}

.greenmoss-light-1 {
    background-color: #576a3d;
}

.greenmoss-light-2 {
    background-color: #fff;
}

.greenmoss-light-3 {
    background-color: #dd9c02;
}

.greenmoss-dark-1 {
    background-color: #627745;
}

.greenmoss-dark-2 {
    background-color: #1e2022;
}

.greenmoss-dark-3 {
    background-color: #8f7b39;
}

.greenlime-light-1 {
    background-color: #6fb327;
}

.greenlime-light-2 {
    background-color: #fff;
}

.greenlime-light-3 {
    background-color: #51c878;
}

.greenlime-dark-1 {
    background-color: #63883b;
}

.greenlime-dark-2 {
    background-color: #1e2022;
}

.greenlime-dark-3 {
    background-color: #4d7058;
}

.carrotorange-light-1 {
    background-color: #ed7117;
}

.carrotorange-light-2 {
    background-color: #fff;
}

.carrotorange-light-3 {
    background-color: #e7523e;
}

.carrotorange-dark-1 {
    background-color: #ad7140;
}

.carrotorange-dark-2 {
    background-color: #1e2022;
}

.carrotorange-dark-3 {
    background-color: #aa4f43;
}

.rubyred-light-1 {
    background-color: #900604;
}

.rubyred-light-2 {
    background-color: #fff;
}

.rubyred-light-3 {
    background-color: #e7284a;
}

.rubyred-dark-1 {
    background-color: #913a47;
}

.rubyred-dark-2 {
    background-color: #1e2022;
}

.rubyred-dark-3 {
    background-color: #aa5e6c;
}

.monsterpurple-light-1 {
    background-color: #922c88;
}

.monsterpurple-light-2 {
    background-color: #fff;
}

.monsterpurple-light-3 {
    background-color: #4556ac;
}

.monsterpurple-dark-1 {
    background-color: #7e4877;
}

.monsterpurple-dark-2 {
    background-color: #1e2022;
}

.monsterpurple-dark-3 {
    background-color: #3c4b9a;
}

.steelgrey-light-1 {
    background-color: #48494b;
}

.steelgrey-light-2 {
    background-color: #fff;
}

.steelgrey-light-3 {
    background-color: #999da0;
}

.steelgrey-dark-1 {
    background-color: #767e8d;
}

.steelgrey-dark-2 {
    background-color: #1e2022;
}

.steelgrey-dark-3 {
    background-color: #4d5a5f;
}

.granolayellow-light-1 {
    background-color: #c0a145;
}

.granolayellow-light-2 {
    background-color: #fff;
}

.granolayellow-light-3 {
    background-color: #e3b778;
}

.granolayellow-dark-1 {
    background-color: #8a722c;
}

.granolayellow-dark-2 {
    background-color: #1e2022;
}

.granolayellow-dark-3 {
    background-color: #a88048;
}

.rtl .landing-page {
    .color-left {
        border-radius: initial;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .color-right {
        border-radius: initial;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .footer-circle-button {
        float: right;
    }

    .components-image {
        left: initial;
        right: 50%;
        transform: translateX(50%);
    }

    .feature-image-left {
        float: left;
    }

    .feature-image-right {
        @include respond-below(sm) {
            float: initial;
            margin-left: initial;
            margin-right: 50%;
            transform: translateX(50%);
        }
    }

    .mobile-hero {
        margin-left: initial;
        transform: translateX(50%);
        margin-right: 50%;
    }

    .mobile-menu ul {
        padding: initial;

        .nav-item+.nav-item {
            margin-right: initial;
            margin-left: initial;
        }
    }

    .mobile-menu-cta {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.blurEffect {
    position: absolute;
    top: 0%;
    right: 0%;
    left: 0%;
    bottom: 0%;
    backdrop-filter: blur(0.8rem);
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-scroll {
    overflow: scroll;
    /* or auto */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar for Internet Explorer and Edge */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.overflow-scroll::-webkit-scrollbar {
    display: none;
  }